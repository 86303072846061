import React, { useState, useRef, useMemo, useEffect }from "react";
import backButton from '../../assets/img/back.png';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { makeStyles } from "@mui/styles";
import SendIcon from '@mui/icons-material/Send';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles(styles);

export default function AnonMailPopup(props) {
  const classes = useStyles();

  const [formStatus, setFormStatus] = useState('Send')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { subject, email, message } = e.target.elements
    let conFom = {
      subject: subject.value,
      email: email.value,
      message: message.value,
    }
    saveAndClose(conFom);
  }

  function isShowMailPopup(){  
    closeMailModal();  
} 

function closeMailModal() {
    props.onMailPopupClose(false);
};

function saveAndClose(conFom){
    props.conFom(conFom);
    closeMailModal();  
}

return (
    <div key={props.id} className={"root-container"} onClick={() => isShowMailPopup()}>
      <div className={classes.contactForm} onClick={e => {e.stopPropagation();}}>
        <div className={classes.tile0}>
        <h2 className="mb-3">Stuur een bericht naar de verhuurder</h2>
        <form onSubmit={onSubmit}>
          <GridContainer spacing={2}>
            <GridItem xs={2} sm={2} md={2}>
              <label className="form-label" htmlFor="email">
                Email
              </label>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
              <input className={classes.contactField} type="email" id="email" required />
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <label className="form-label" htmlFor="subject">
                Onderwerp
              </label>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
              <input className={classes.contactField} type="text" id="subject" required />
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <label className="form-label" htmlFor="message">
                Bericht
              </label>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
              <textarea className={classes.contactField} id="message" rows={4} required />
            </GridItem>
          </GridContainer>
          {/* <img  src={backButton} alt="back" className="backImage" onClick={() => isShowMailPopup()}/> */}
          <button className="btn btn-danger" type="submit">
          <SendIcon fontSize="large"/>
          </button>
        </form>
      </div>

        </div>
       </div>
    )
  }