import { red } from "@mui/material/colors";
import { Autorenew, Block } from "@mui/icons-material";
import { title } from "../../../material-kit-react.js";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#00796b",
    marginLeft: "2%"
  },
  resTitle: {
    color: "#00796b",
    fontSize: "calc(.5em + 1vw)"
  },
  resText: {
    color: "#00796b"
  },
  resTextMargin: {
    color: "#00796b",
    marginTop: "2%"
  },
  labelText: {
    verticalAlign: "top"
  },
  featureTitle: {
    color: "black",
    textTransform: "uppercase",
    listStyleType: "square",
  },
  image: {
    width: "85%",
    marginLeft: "2%"
  },
  backImage: {
    width: "12%",
    paddingRight: "5%",
  },
  startTiles: {
    paddingTop: "50px",
  },
  tile0: {
    borderRadius: "15px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    background: "#d6dec4",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "2%"
  },
  tile1: {
    borderRadius: "15px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    background: "#e0f2f1",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "2%"
  },
  dateRange: {
    paddingBottom: "1%",
    textAlign: "left"
  },
  borderDiv: {
    border: "solid #969696",
    borderWidth: "medium",
    borderRadius: "5%",
    // height: "95%"
  },
  borderText: {
    marginLeft: "5%",
    color: "#00796b",
    listStyleType: "square",
    listStylePosition: "inside",
    fontSize: "small"
  },
  price: {
    textAlign: "center",
    paddingTop: "4%",
    fontSize: "xx-large"
  },
  error: {
    textAlign: "left",
    color: "red",
    fontSize: "small"
  },
  rButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  lButton: {
    display: "block",
    marginRight: "auto"
  },
  priceTitle: {
    color: "#00796b",
    fontSize: "calc(.5em + 1vw)",
    marginTop: "2%",
    marginBottom: "2%"
  },
  mPriceTitle: {
    color: "#00796b",
    fontSize: "calc(1em + 1vw)",
    marginTop: "2%",
    marginBottom: "2%"
  },
//Input
discountInput: {
  height: "1.8em",
  width: "100%",
  borderRadius: "3px",
  border: "1px solid $blue_theme",
  transition: "border 0.4s ease-out",
  padding: "8px",
  fontFamily: "Oxygen, sans-serif",
  fontSize: "18px",
  color: "#3e3e42",
  background: "rgba(15, 15, 15, 0.01)"
},
discountText: {
  color: "red"
},
headerText: {
  color: "grey"
},
featureImg: {
  width: "50%",
  verticalAlign: "bottom",
  marginTop: "25%"
},
featureVal: {
  display: "inline",
  marginLeft: "5%"
},
buttonContact: {
  textAlign: "center"
},
contactForm: {
  textAlign: "left",
  borderRadius: "15px",
  boxShadow:
    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  background: "#e0f2f1",
  padding: "1%",
},
contactField: {
  maxWidth: "80%",
  width: "80%"
}
};

export default productStyle;
