import React, { useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import Rating from "react-rating";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import {addReview} from "../../services/HouseService";
import Button from "@mui/material/Button";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const useStyles = makeStyles(styles);

function Review(props){

    let cookies = props.cookies;
    const classes = useStyles();
    const token = cookies.get('token');
    const [reviewAdded, setreviewAdded] = useState(false);
    const [extra, setExtra] = useState({review: {good: '', better: '', bad: '', house: 0, location: 0}, eta : ''});

    useEffect(() => {
        if (props.house.reviews.filter(h => h.bookingId === props.bookingId).length > 0){
            setreviewAdded(true);
        }
    
    }, [props.house]);

    function setReview(e){
        const good = e.target.name === 'good' ? e.target.value : extra.review.good;
        const better = e.target.name === 'better' ? e.target.value : extra.review.better;
        const bad = e.target.name === 'bad' ? e.target.value : extra.review.bad;
        const rev = {review: {good: good, better: better, bad: bad, house: extra.review.house, location: extra.review.location}};
        setExtra(extra => ({
            ...extra,
            ...rev
          }));
     }

     function setStarsHouse(stars){
        const rev = {review: {good: extra.review.good, better: extra.review.better, bad: extra.review.bad, house: stars, location: extra.review.location}};
        setExtra(extra => ({
          ...extra,
          ...rev
        }));
       }
      
       function setStarsLocation(stars){
        const rev = {review: {good: extra.review.good, better: extra.review.better, bad: extra.review.bad, house:  extra.review.house, location: stars}};
        setExtra(extra => ({
          ...extra,
          ...rev
        }));
       }

       function submitReview(){
        console.log(extra);
        const rev = {bookingId: props.bookingId,
                     houseId: props.house.id,
                     good: extra.review.good,
                     better: extra.review.better,
                     bad: extra.review.bad,
                     scoreHouse: extra.review.house,
                     scoreLocation: extra.review.location};
            addReview(rev, token).then(res => {
            if (res) {
                setreviewAdded(true);
            } else {
                setreviewAdded(false);
                console.log('error adding review!')
            }
        });
       }

    return (
        <div className={classes.labelAbove}>
            {!reviewAdded && <div>
                    <label className={classes.labelText}>Laat a.u.b. een review achter:</label>
                    <label className={classes.labelText}> Dit was goed:</label>
                      <textarea name="good"
                              onChange={setReview}
                              value={extra.review.good}
                              cols={40}
                              rows={2}
                              wrap="hard"/>
                      <label className={classes.labelText}>Dit kon beter:</label>
                      <textarea name="better"
                              onChange={setReview}
                              value={extra.review.better}
                              cols={40}
                              rows={2}
                              wrap="hard"/>
                      <label className={classes.labelText}>Dit was niet goed:</label>
                      <textarea name="bad"
                              onChange={setReview}
                              value={extra.review.bad}
                              cols={40}
                              rows={2}
                              wrap="hard"/>
                       <label className={classes.labelText}>Aantal sterren voor het huis:</label>
                       <Rating stop={10} fractions={2} onClick={setStarsHouse}  initialRating={extra.review.house}/>
                       <label className={classes.labelText}>Aantal sterren voor de omgeving:</label>
                       <Rating stop={10} fractions={2} onClick={setStarsLocation} initialRating={extra.review.location}/>
                       <Button
                            type="button"
                            variant="contained"
                            color="error"
                            className={classes.labelAbove}
                            onClick={submitReview}
                            >Versturen
                        </Button>
                </div>}
            {reviewAdded && <div className={classes.messageBox}>
                <label className={classes.labelText}>U heeft een review achtergelaten.</label>
                <label className={classes.labelText}>Bedankt daarvoor! <ThumbUpAltIcon fontSize="large"/></label>
                
            </div>}
        </div>
    )
}

export default withCookies(Review);