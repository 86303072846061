import React, { useState, useEffect }from "react";
import FadeTransition from "./FadeTransition";
import { LoginBox } from "./LoginBox";
import { RegisterBox } from "./RegisterBox";
import "./loginStyles.scss";
import backButton from '../../assets/img/back.png';
import {
  useNavigate, useLocation
} from "react-router-dom";

export default function MLoginRegister (props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [isLoginOpen, setIsLoginOpen] = useState(true);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [goBack, setGoBack] = useState(false);
    const backUrl =(location.state !== undefined && location.state !== null) ? location.state.url : null;

    useEffect(() => {
    if ( goBack )   {
      if (backUrl === null) {
        navigate(-1);
      } else {
        navigate(backUrl);
      }
    }     
    }, [goBack]);


    function showLoginBox() {
      console.log("login");
        setIsLoginOpen( true);
       setIsRegisterOpen(false);
      }
    
     function showRegisterBox() {
        console.log("register");
        setIsLoginOpen( false);
        setIsRegisterOpen(true);
      }

      function closeModal  (param)  {
        setGoBack(true);
      };

      return (
        <div className="root-container-m" onClick={() => setGoBack(true)}>
          <div className="box-container1-m" onClick={e => {e.stopPropagation();}}>
              <div className="box-controller-m">
                  <div
                      className={"controller " + (isLoginOpen
                      ? "selected-controller1"
                      : "")}
                      onClick={showLoginBox
                      }>
                      Inloggen
                  </div>
                  <div
                      className={"controller " + (isRegisterOpen
                      ? "selected-controller2"
                      : "")}
                      onClick={showRegisterBox
                      }>
                      Registreren
                  </div>
              </div>
              <div>
                  <FadeTransition isOpen={isLoginOpen} duration={100}>
                    <div className="box-container1-m">
                        <LoginBox closeModal={closeModal}/>
                    </div>
                  </FadeTransition>
                  <FadeTransition isOpen={isRegisterOpen} duration={100}>
                    <div className="box-container2-m">
                        <RegisterBox closeModal={closeModal}/>
                    </div>
                  </FadeTransition>
                  <div>
                     <img  src={backButton} alt="back" className="backImage" onClick={() => setGoBack(true)}/>
                  </div>
              </div>
          </div>
      </div>
      );
    }
  // }