import React, { useState } from "react";
import { useParams, useLocation  } from "react-router-dom";
import { useQuery } from 'react-query';
import { makeStyles } from "@mui/styles";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import {fetchAllHouses, fetchImageUrl} from "services/HouseService";
import { sendAnonMailWithBody } from "services/CommunicationService.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {getHtml} from "utils/StringFunctions";
import { isEvenOrOdd } from "utils/NumberFunctions";
import { Link } from 'react-router-dom';
import backButton from '../../assets/img/back.png';
import DateRangePicker from "../booking/DateRange";
import CheckRange from "../booking/CheckRange";
import Carousel from "components/Carousel/Carousel";
import AnonMailPopup from "views/shared/AnonMailPopup.js";
import Button from '@mui/material/Button';
 
const useStyles = makeStyles(styles);

export default function House()  {

    const { id } = useParams();
    const location = useLocation();
    const classes = useStyles();
    const overviewLink = "/";
    let counter = 0;
    const {data, error, isError, isLoading } = useQuery('houses', fetchAllHouses);
    const [range, setRange] = useState({});
    const reset = location.state ? location.state.reset : false;
    const [showMailModal, setShowMailModal] = useState(false);
    // const [bookingInfo, setBookingInfo] = useState({});

    if(isLoading){
        return <div>Loading...</div>
    }
    if(isError){
        return <div>Error! {error.message}</div>
    }

    function getTileClass() {
        console.log(reset);
        counter++;
        console.log(counter);
        if (isEvenOrOdd(counter)) return classes.tile0;
        return classes.tile1;
     }

    function handleCallback (childData) {
        // console.log(childData);
        setRange({range: childData})
    }

    function isShowMailModal(param){  
        setShowMailModal(param); 
      }

    
    function sendConFom(conFom){
    console.log(conFom);
    setShowMailModal(true); 
        sendAnonMailWithBody(id, conFom.subject, conFom.email, conFom.message).then(res => {
            console.log(res);
        });
    }

    return (
        <div className={classes.startTiles}>
            {data.filter(hz => hz.id == id).map((house, idx) =>
            <div className={getTileClass(house.id)} key={house.id}>               
            <GridContainer spacing={2} >
                <GridItem xs={12} sm={12} md={6}> 
                    <h3 className={classes.description}>
                        <Link to={overviewLink} >
                            <img  src={backButton} alt="back" className={classes.backImage}/>
                        </Link>
                            {house.name} in {house.houseAddress.city}, {house.houseAddress.country}
                    </h3>
                    <Carousel>
                        {house.houseImages.map(hzImg => <img  src={fetchImageUrl(house.id, hzImg.path)} alt="Pic" key={hzImg.seqno}/>)}
                    </Carousel>
                    <div className={classes.buttonContact}>
                        <Button onClick={() => setShowMailModal(true)} variant="contained" color="success" className={classes.buttonMailType}>Neem contact op met de verhuurder</Button>
                        {showMailModal && <AnonMailPopup onMailPopupClose={isShowMailModal} conFom={sendConFom}/>}
                     </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}> 
                <h5 className={classes.description}>
                    <div dangerouslySetInnerHTML = {getHtml( house.description)}></div>
                    {house.features.sort((a,b) =>  a.seqNo-b.seqNo ).map((filteredFeat, idx2) => (
                        <GridItem xs={12} sm={12} md={12} key={idx2}>
                            <li className={classes.featureTitle}>{filteredFeat.feature}</li>
                            <div dangerouslySetInnerHTML = {getHtml(filteredFeat.value)}></div>
                        </GridItem>
                    ))}
                </h5>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.borderDiv}>
                    <CheckRange houseId={house.id} range={range} reset={reset}/>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <div className={classes.dateRange}>
                      <DateRangePicker houseId={house.id}  parentCallback = {handleCallback}/>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
            )}
        </div>
    )
        
}
