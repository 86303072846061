import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const styles = {
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
    // border: "1px solid",
    borderRadius: "15px",
  },
};

const useStyles = makeStyles(styles);

export default function BorderedGridItem(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

BorderedGridItem.defaultProps = {
  className: "",
};

BorderedGridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
