import React from "react";
import validator from "validator";
import { register } from "services/AuthService";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import {isMobile} from 'react-device-detect';

class NormalRegisterBox extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      telephone: "",
      address: "",
      number:"",
      zipCode: "",
      city: "",
      country: "",
      login:true,
      errors: [],
      pwdState: null,
      error: ""
    };
  }

  showValidationErr(elm, msg) {
    this.setState((prevState) => ({
      errors: [
        ...prevState.errors, {
          elm,
          msg
        }
      ]
    }));
  }

  clearValidationErr(elm) {
    this.setState((prevState) => {
      let newArr = [];
      for (let err of prevState.errors) {
        if (elm !== err.elm) {
          newArr.push(err);
        }
      }
      console.log(this.state);
      return {errors: newArr};
    });
  }

  addValidationErr(elm){
    let found = false;
    this.setState((prevState) => {
      let newArr = [];
      for (let err of prevState.errors) {
        newArr.push(elm);
        if (elm === err.elm) {
          found = true;
        }
      }

      if (!found) newArr.push(elm);
      return {errors: newArr};
    });
    
  }

  onEmailChange(e) {
    this.setState({email: e.target.value});
    if (validator.isEmail(e.target.value)) {
      this.clearValidationErr("email");
    } 
  }

  onFirstNameChange(e) {
    this.setState({firstName: e.target.value});
    this.clearValidationErr("firstName");
  }

  onLastNameChange(e) {
    this.setState({lastName: e.target.value});
    this.clearValidationErr("lastName");
  }

  onTelephoneChange(e) {
    this.setState({telephone: e.target.value});
    this.clearValidationErr("telephone");
  }

  onAddressChange(e) {
    this.setState({address: e.target.value});
    this.clearValidationErr("address");
  }

  onNumberChange(e) {
    this.setState({number: e.target.value});
    this.clearValidationErr("number");
  }

  onZipCodeChange(e) {
    this.setState({zipCode: e.target.value});
    this.clearValidationErr("zipCode");
  }

  onCityChange(e) {
    this.setState({city: e.target.value});
    this.clearValidationErr("city");
  }

  onCountryChange(e) {
    this.setState({country: e.target.value});
    this.clearValidationErr("country");
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
    this.clearValidationErr("password");

    this.setState({pwdState: "weak"});
    if (e.target.value.length > 8) {
      this.setState({pwdState: "medium"});
    } 
    if (validator.isStrongPassword(e.target.value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })){
      this.setState({pwdState: "strong"});
    }

  }

  onLoginChange(e){
    this.setState({login: !this.state.login});
  }

  openPopup(e) {
    console.log("Hello world!");
    this.submitRegister(e);
  }

  submitRegister(e) {

    console.log(this.state);
    let errors = false;

    if (this.state.email === "") {
      this.showValidationErr("email", "Email mag niet leeg zijn!");
      errors = true;
    }
    if (!validator.isEmail(this.state.email)) {
      this.showValidationErr("email", "Email moet geldig zijn!");
      errors = true;
    }
    if (this.state.firstName === "") {
      this.showValidationErr("firstName", "Voornaam mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.lastName === "") {
      this.showValidationErr("lastName", "Achternaam mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.telephone === "") {
      this.showValidationErr("telephone", "Telefoon no. mag niet leeg zijn!");
      errors = true;
    }
    if (!validator.isMobilePhone(this.state.telephone)) {
      this.showValidationErr("telephone", "Telefoon no. moet geldig zijn!");
      errors = true;
    }
    if (this.state.address === "") {
      this.showValidationErr("address", "Adres mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.number === "") {
      this.showValidationErr("number", "Nummer mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.zipCode === "") {
      this.showValidationErr("zipCode", "Postcode mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.city === "") {
      this.showValidationErr("city", "Stad mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.country === "") {
      this.showValidationErr("country", "Land mag niet leeg zijn!");
      errors = true;
    }
    if (this.state.password === "") {
      this.showValidationErr("password", "Wachtwoord mag niet leeg zijn!");
      errors = true;
    }

    if (!errors)  this.registerNewUser();

  }

  async registerNewUser(){
    if (this.state.errors.length === 0){
      console.log("geen errors");
      let user = {
        userEmail: this.state.email,
        password: this.state.password,
        nameFirst: this.state.firstName,
        nameLast: this.state.lastName,
        userTelephone: this.state.telephone,
        userAddress: { street: this.state.address,
        number: this.state.number,
        zip: this.state.zipCode,
        city: this.state.city,
        country: this.state.country
        },
        role: "user",
        login: this.state.login
      }

      const json = await register(user);
      if (json.token) {

        const { cookies } = this.props;
        console.log(json);
          this.setState({error: ''});
          cookies.set('user', json.nameFirst + ' ' + (json.nameMiddle ? json.nameMiddle + ' ' : '') + json.nameLast, { path: '/', secure: true, sameSite: 'none'});
          cookies.set('token', json.token, { path: '/', secure: true, sameSite: 'none'});
          this.props.closeModal();
      } else {
        if (!this.state.login) this.props.closeModal();
        this.setState({error: 'Gebruiker bestaat al, of er ging iets mis!'});
      }

    }
  }

  render() {

    let passwordErr = null,
      emailErr = null,
      firstNameErr = null,
      lastNameErr = null,
      telephoneErr = null,
      addressErr = null,
      numberErr = null,
      zipCodeErr = null,
      cityErr = null,
      countryErr = null;

    for (let err of this.state.errors) {
      if (err.elm === "password") {
        passwordErr = err.msg;
      }
      if (err.elm === "email") {
        emailErr = err.msg;
      }
      if (err.elm === "firstName") {
        firstNameErr = err.msg;
      }
      if (err.elm === "lastName") {
        lastNameErr = err.msg;
      }
      if (err.elm === "telephone") {
        telephoneErr = err.msg;
      }
      if (err.elm === "address") {
        addressErr = err.msg;
      }
      if (err.elm === "number") {
        numberErr = err.msg;
      }
      if (err.elm === "zipCode") {
        zipCodeErr = err.msg;
      }
      if (err.elm === "city") {
        cityErr = err.msg;
      }
      if (err.elm === "country") {
        countryErr = err.msg;
      }
    }

    let pwdWeak = false,
      pwdMedium = false,
      pwdStrong = false;

    if (this.state.pwdState === "weak") {
      pwdWeak = true;
    } else if (this.state.pwdState === "medium") {
      pwdWeak = true;
      pwdMedium = true;
    } else if (this.state.pwdState === "strong") {
      pwdWeak = true;
      pwdMedium = true;
      pwdStrong = true;
    }

    return (
      <div className="inner-container">
        <div className="header">
          Registreren
        </div>
        <div className="box-wrap">

        <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              className="login-input"
              placeholder="Email"
              onChange={this
              .onEmailChange
              .bind(this)}/>
            <small className="danger-error">{emailErr
                ? emailErr
                : ""}</small>
          </div>

          <div className="input-group">
            <label htmlFor="password">Wachtwoord</label>
            <input
              type="password"
              name="password"
              className="login-input"
              placeholder="Wachtwoord"
              onChange={this
              .onPasswordChange
              .bind(this)}/>
            <small className="danger-error">{passwordErr
                ? passwordErr
                : ""}</small>

            {this.state.password && <div className="password-state">
              <div
                className={"pwd pwd-weak " + (pwdWeak
                ? "show1"
                : "")}></div>
              <div
                className={"pwd pwd-medium " + (pwdMedium
                ? "show2"
                : "")}></div>
              <div
                className={"pwd pwd-strong " + (pwdStrong
                ? "show3"
                : "")}></div>
            </div>}

          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="firstName">Voornaam</label>
            <input
              type="text"
              name="firstName"
              className="login-input"
              placeholder="Voornaam"
              onChange={this
              .onFirstNameChange
              .bind(this)}/>
            <small className="danger-error">{firstNameErr
                ? firstNameErr
                : ""}</small>
          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="lastName">Achternaam</label>
            <input
              type="text"
              name="lastName"
              className="login-input"
              placeholder="Achternaam"
              onChange={this
              .onLastNameChange
              .bind(this)}/>
            <small className="danger-error">{lastNameErr
                ? lastNameErr
                : ""}</small>
          </div>

          <div className="input-group">
            <label htmlFor="telephone">Telefoon no.</label>
            <input
              type="text"
              name="telephone"
              className="login-input"
              placeholder="Telefoon no."
              onChange={this
              .onTelephoneChange
              .bind(this)}/>
            <small className="danger-error">{telephoneErr
                ? telephoneErr
                : ""}</small>
          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="address">Adres</label>
            <input
              type="text"
              name="address"
              className="login-input"
              placeholder="Adres"
              onChange={this
              .onAddressChange
              .bind(this)}/>
            <small className="danger-error">{addressErr
                ? addressErr
                : ""}</small>
          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="number">Nummer</label>
            <input
              type="text"
              name="number"
              className="login-input"
              placeholder="Nummer"
              onChange={this
              .onNumberChange
              .bind(this)}/>
            <small className="danger-error">{numberErr
                ? numberErr
                : ""}</small>
          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="zipCode">Postcode</label>
            <input
              type="text"
              name="zipCode"
              className="login-input"
              placeholder="Postcode"
              onChange={this
              .onZipCodeChange
              .bind(this)}/>
            <small className="danger-error">{zipCodeErr
                ? zipCodeErr
                : ""}</small>
          </div>

          <div className={!isMobile ? "input-group" : "input-group-50"}>
            <label htmlFor="city">Stad</label>
            <input
              type="text"
              name="city"
              className="login-input"
              placeholder="Stad"
              onChange={this
              .onCityChange
              .bind(this)}/>
            <small className="danger-error">{cityErr
                ? cityErr
                : ""}</small>
          </div>

          <div className="input-group">
            <label htmlFor="country">Land</label>
            <input
              type="text"
              name="country"
              className="login-input"
              placeholder="Land"
              onChange={this
              .onCountryChange
              .bind(this)}/>
            <small className="danger-error">{countryErr
                ? countryErr
                : ""}</small>
          </div>

          <div >
            <label htmlFor="login">Direct inloggen</label>
            <input
               type="checkbox"
               name="login"
               checked={this.state.login}
               placeholder="login"
               onChange={this
                .onLoginChange
                .bind(this)}/>
          </div>

          <button
            type="button"
            className="login-btn"
            onClick={this
            .openPopup
            .bind(this)}>Registreren</button>

        </div>
        <div className="error" >{this.state.error}</div>
      </div>

    );
  }
}

const RegisterBox = withCookies(NormalRegisterBox);
export { RegisterBox };