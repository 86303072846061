export default function StringFunctions (){

}

function getHtml (str) {
    return { __html: str };
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function stringify(json){
    return JSON.stringify(json);
}

function parse(str){
    if (isJsonString(str)) return JSON.parse(str);
}

export {getHtml, isJsonString, stringify, parse}