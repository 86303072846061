import React from "react";
import FadeTransition from "./FadeTransition";
import { LoginBox } from "./LoginBox";
import { RegisterBox } from "./RegisterBox";
import "./loginStyles.scss";
import backButton from '../../assets/img/back.png';

export default class LoginRegister extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        isLoginOpen: true,
        isRegisterOpen: false,
        showModal: false
      };
    }



    showLoginBox() {
      console.log("login");
        this.setState({isLoginOpen: true, isRegisterOpen: false});
      }
    
      showRegisterBox() {
        console.log("register");
        this.setState({isRegisterOpen: true, isLoginOpen: false});
      }

      isShowModal = (status) => {  
        this.closeModal({status: 3});  
        this.setState({ showModal: status });  
    } 

      closeModal = (param) => {
        this.props.onModalClose(param);
      };
  
    render() {
      if (!this.props.showModal && !this.state.showModal) {
        return null;
      }

      return (
        <div className="root-container" onClick={() => this.isShowModal(true)}>
          <div className="box-container1" onClick={e => {e.stopPropagation();}}>
              <div className="box-controller">
                  <div
                      className={"controller " + (this.state.isLoginOpen
                      ? "selected-controller1"
                      : "")}
                      onClick={this
                      .showLoginBox
                      .bind(this)}>
                      Inloggen
                  </div>
                  <div
                      className={"controller " + (this.state.isRegisterOpen
                      ? "selected-controller2"
                      : "")}
                      onClick={this
                      .showRegisterBox
                      .bind(this)}>
                      Registreren
                  </div>
              </div>
              <div>
                  <FadeTransition isOpen={this.state.isLoginOpen} duration={100}>
                    <div className="box-container1">
                        <LoginBox closeModal={this.closeModal}/>
                    </div>
                  </FadeTransition>
                  <FadeTransition isOpen={this.state.isRegisterOpen} duration={100}>
                    <div className="box-container2">
                        <RegisterBox closeModal={this.closeModal}/>
                    </div>
                  </FadeTransition>
                  <div>
                      {/* <button  onClick={() => this.isShowModal(true)} >
                       Close
                     </button> */}
                     <img  src={backButton} alt="back" className="backImage" onClick={() => this.isShowModal(true)}/>
                  </div>
              </div>
        </div>
       </div>
      );
    }
  }