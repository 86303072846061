import React, { useState, useEffect, useRef } from "react";
import { useQuery } from 'react-query';
import { withCookies } from "react-cookie";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {Dayjs} from 'dayjs';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { DeleteForever } from '@mui/icons-material';
import moment from 'moment/dist/moment.js';
import { fetchDiscountCodes } from "../../../services/BookingService.js";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from "@mui/material/Button";
import { saveDiscount } from "../../../services/BookingService.js";
import validator from "validator";
import TabPanel from "../../shared/TabPanel.tsx";

const useStyles = makeStyles(styles);

function Discounts(props){
  let cookies = props.cookies;
  const token = cookies.get('token');
  const classes = useStyles();
  const house = props.house;
  const {data: discounts, error: discountError, isLoading: discountIsLoading, refetch } = useQuery(['discounts', house.id, token], () =>  fetchDiscountCodes(house.id, token));
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(null);
  const [valueTo, setValueTo] = useState<Dayjs | null>(null);
  const initialState = {id: 0
                      , houseId: 0
                      , couponCode: ""
                      , userEmail: ""
                      , percentage: 0.0
                      , amount: 0.0
                      , expired: false
                      , validFrom: null
                      , validTo: null
                      , number: 0};
  const [discount, setDiscount] = useState(initialState);
  const [error, setError] = useState("");

  if(discountIsLoading){
    return <div>Loading for houseId {house.id}...</div>
}
if(discountError){
    return <div>No data found!</div>
}

function handleChange(event){
  setDiscount({...discount, [event.target.name]: event.target.value });
}

function handleSubmit(){
  if (discount.couponCode === "") {
    setError("Vul in ieder geval een kortingscode in.");
    return;
  }
  if (discount.percentage <= 0.0 && discount.amount <= 0.0) {
    setError("Vul een percentage of bedrag in.");
    return;
  }
  discount.houseId = house.id;
  discount.validFrom = valueFrom;
  discount.validTo = valueTo;
  if (discount.userEmail && !validator.isEmail(discount.userEmail)) {
    setError("Vul een geldig email adres in.");
    return;
  }

  saveDiscount(discount, token).then(res => {
    if (res) {
        console.log("Gelukt");
        refetch();
        setDiscount(initialState);
        setValueFrom(null);
        setValueTo(null);
        setError("");
    } else {
        console.log("Mislukt");
    }
  })
}

const handleExpired = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log(discount);
  setDiscount({...discount, expired: event.target.checked });

};

function editExisting(id){
  const disc = discounts.filter(d => d.id === id)[0];
  disc.userEmail = disc.userEmail ? disc.userEmail : "";
  disc.percentage = disc.percentage ? disc.percentage : 0.0;
  disc.amount = disc.amount ? disc.amount : 0.0;
  disc.expired = disc.expired ? disc.expired : false;
  disc.number = disc.number ? disc.number : 0;
  if (disc.validFrom) setValueFrom(dayjs(disc.validFrom));
  if (disc.validTo) setValueTo(dayjs(disc.validTo));
  setDiscount(disc);
}

    return (
        <TabPanel value={props.value} index={props.index}>
            <div className={classes.tile}>
            
              <FormControl>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={12} md={6}> 
                    <Paper style={{ padding: 10 }}>
                      <GridContainer spacing={2}>
                          <GridItem xs={12}>
                            <TextField
                              fullWidth
                              required
                              size="small"
                              name="couponCode"
                              type="text"
                              label="Kortingscode"
                              value={discount.couponCode}
                              onChange={handleChange}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              name="userEmail"
                              type="text"
                              label="Geldig voor email, laat leeg als algemeen"
                              value={discount.userEmail}
                              onChange={handleChange}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              name="percentage"
                              fullWidth
                              size="small"
                              type="text"
                              label="Kortingspercentage"
                              value={discount.percentage}
                              onChange={handleChange}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              name="amount"
                              fullWidth
                              size="small"
                              type="text"
                              label="Kortingsbedrag"
                              value={discount.amount}
                              onChange={handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                      </Paper>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}> 
                     <Paper style={{ padding: 10 }}>
                      <GridContainer spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <GridItem xs={12}>
                            <DatePicker
                              label="Geldig van"
                              value={valueFrom}
                              inputFormat="DD-MM-YYYY"
                              onChange={(newValue) => {
                                setValueFrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} size="small" fullWidth/>}
                            />
                            </GridItem>
                            <GridItem xs={12}>
                            <DatePicker
                              label="Geldig tot"
                              value={valueTo}
                              inputFormat="DD-MM-YYYY"
                              onChange={(newValue) => {
                                setValueTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} size="small" fullWidth/>}
                            />
                            </GridItem>
                          </LocalizationProvider>
                          <GridItem xs={12}>
                            <TextField
                              name="number"
                              fullWidth
                              size="small"
                              type="text"
                              label="Aantal keer te gebruiken"
                              value={discount.number}
                              onChange={handleChange}
                            />
                          </GridItem>
                          <GridContainer spacing={2} className={classes.smallContainer}>
                          <GridItem xs={6}>
                            <Stack direction="row" spacing={1} alignItems="center" > 
                              <Typography>Inactief</Typography>
                                        <Switch
                                            checked={discount.expired}
                                            onChange={handleExpired}

                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                            </Stack>
                            </GridItem>
                            <GridItem xs={6}>
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                className="check-btn"
                                onClick={handleSubmit}
                                >Opslaan
                            </Button> 
                          </GridItem>
                          <GridItem>
                            <div className={classes.errorText}>
                                {error}
                            </div>
                          </GridItem>
                          </GridContainer>
                      </GridContainer>
                      </Paper>
                    </GridItem>
                  </GridContainer>
              </FormControl>

            </div>
            <div className={classes.tile}>
            <GridItem xs={6} sm={6} md={12}> 
                  <Typography className={classes.subTitle}>Bestaande kortingscodes</Typography>
                    <TableContainer component={Paper} className={classes.gridHeight}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead >
                        <TableRow className={classes.tableRow}>
                          <TableCell>Code</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Percentage</TableCell>
                          <TableCell>Bedrag</TableCell>
                          <TableCell>Geldig van</TableCell>
                          <TableCell>Geldig tot</TableCell>
                          <TableCell>Aantal</TableCell>
                          <TableCell>Inactief</TableCell>
                          <TableCell/>
                          <TableCell/>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discounts.map((d, idx) => (
                          <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className={classes.tableRow}
                          >
                            <TableCell>{d.couponCode}</TableCell>
                            <TableCell>{d.userEmail}</TableCell>
                            <TableCell>{d.percentage}</TableCell>
                            <TableCell>{d.amount}</TableCell>
                            <TableCell>{d.validFrom ? dayjs(d.validFrom).format('DD-MM-YYYY') : null}</TableCell>
                            <TableCell>{ d.validTo ? dayjs(d.validTo).format('DD-MM-YYYY') : null}</TableCell>
                            <TableCell>{d.number}</TableCell>
                            <TableCell><Switch
                                            checked={d.expired}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        /></TableCell>
                            <TableCell className={classes.tableCell}><Button variant="contained" onClick={() => editExisting(d.id)}><EditOutlinedIcon/></Button></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              </GridItem>
              </div>
        </TabPanel>
    )
    
}

export default withCookies(Discounts);