import React, {useEffect} from 'react'
// import HouseOverview from './houses/HouseOverView';
import LandingPage from 'views/LandingPage';

function App() {

  useEffect(() => {
    document.title = 'Holiday-Home.online'
}, []);

  return  (
    <LandingPage />
    )
}

export default App;
