import componentsStyle from 'assets/jss/material-kit-react/views/components';
import moment from 'moment/dist/moment.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

let bookingInfo = {minimum: 0
                ,advanceMet: false
                ,periods: []
                ,error: []
                ,valid: false
                ,price: 0
                ,chosen: []
                ,range: {}
                };

function resetBookingInfo(){
    bookingInfo.minimum = 0;
    bookingInfo.advanceMet = false;
    bookingInfo.periods = [];
    bookingInfo.error = [];
    bookingInfo.valid = false;
    bookingInfo.price = 0;
    bookingInfo.chosen = [];
    bookingInfo.range = {};
}

function minimumNights (prices){
    const nights = prices.map(p => p.period.nights);
    bookingInfo.minimum = Math.min(...nights);
}

function maximumNights (prices){
    const nights = prices.map(p => p.period.nights);
    return Math.max(...nights);
}

function periodByNights (prices, nights){
    const max = maximumNights(prices);
    if (nights < max){
        return prices.filter(p => p.period.nights === nights);
    } else {
        return prices.filter(p => p.period.nights === max);
    }
}

function addError (no, par){
    bookingInfo.error.push({ "no": no, "parameter": par} );
}

function daysInAdvance (range){
    //minimum days in advance must be retrieved from database
    bookingInfo.error = [];
    bookingInfo.price = 0;
    if (new moment(range.range[0].startDate).isAfter(new moment().add(3, 'days'))) {
        bookingInfo.advanceMet = true;
    } else {
        bookingInfo.advanceMet = false;
        addError (3, "3")
    }
}

function rangeSelected (range, prices){
    if (range.range === undefined) return false;
    const startDate = new moment(range.range[0].startDate, 'YYYY-MM-DD');
    const endDate = new moment(range.range[0].endDate, 'YYYY-MM-DD');
    if (moment(startDate).isSame(endDate)){
        return false;
    } 
    addPeriodPrices(startDate, prices);
    return true;
}

function addChosenPeriod (description){
    if (bookingInfo.chosen.length > 0){
        let index = bookingInfo.chosen.findIndex(c => c.period === description);
        if (index > -1) {
            bookingInfo.chosen[index].no = bookingInfo.chosen[index].no + 1;
        } else {
            bookingInfo.chosen.push({"period": description, "no": 1});
        }
    } else {
        bookingInfo.chosen.push({"period": description, "no": 1});
    }
}

function addPeriodPrices(startDate, prices){
    let periods = prices.filter(p => startDate.isBetween(new moment(p.fromDate, 'YYYY-MM-DD'), new moment(p.toDate, 'YYYY-MM-DD') ));
    periods.forEach(p => {
            let index = bookingInfo.periods.findIndex(c => c.description === p.period.description);
            if (index > -1)  {
                bookingInfo.periods[index].price = p.price;
                bookingInfo.periods[index].minimumStay = p.minimumStay;
            }
        }
        )
}

function periods(prices){
    
    let filtered = [];
    prices.map(p => p.period).sort((a,b) =>  a.nights-b.nights ).map(p => {
        if (filtered.filter(f => f.id === p.id).length > 0) {
            return filtered
        }  else { return filtered.push(p) }});
    filtered.forEach(per => {
        bookingInfo.periods.push({"id": per.id, "description": per.description, "fromDay": per.fromDay, "toDay": per.toDay, "nights": per.nights, "minimumStay": 0, "price": 0});
    });
}

function minimumStayCheck(prices){
    let minimumStayConfigured = bookingInfo.periods.filter(f => f.minimumStay !== null);
    // console.log(1);
        // console.log(minimumStayConfigured);
    // console.log(bookingInfo.chosen);
    if (minimumStayConfigured.length > 0 && bookingInfo.chosen.length === 1){
        minimumStayConfigured.forEach(m => {
            if (bookingInfo.chosen.findIndex(i => i.period === m.description && i.no < m.minimumStay) > -1) addError(4, m.minimumStay);
        })
    }
}
function checkRangePeriod (prices, range){
    bookingInfo.chosen = [];
    const startDate = new moment(range.range[0].startDate, 'YYYY-MM-DD');
    const endDate = new moment(range.range[0].endDate, 'YYYY-MM-DD');
    const nights = endDate.diff(startDate, 'days');
    const startDay = new moment(range.range[0].startDate).format('dddd').toLowerCase(); 
    const endDay = new moment(range.range[0].endDate).format('dddd').toLowerCase(); 
    // console.log('checkRange:');
    // console.log(startDay + ' ' + endDay + ' ' + nights );

    //check if the start day and end day are allowed start-and end day.
    //only show error when choosing of other start days are disallowed (i.e. minimum nights > 1)
    const startDayValid = prices.filter(p => p.period.fromDay === startDay).length > 0;
    const endDayValid = prices.filter(p => p.period.toDay === endDay).length > 0;

    if ((startDayValid && endDayValid && bookingInfo.advanceMet) || (nights >= bookingInfo.minimum && bookingInfo.minimum === 1)){
        //nights + 1 >= bookingInfo.minimum
        bookingInfo.price = calculatePrice(prices, startDate, startDay, nights);
        minimumStayCheck(prices);
    } else {
        if (!startDayValid) addError(1, "");
        if (!endDayValid) addError(2, "");
    }
}

function calculatePrice(prices, startDate, startDay, nights){
    let sDate = startDate;
    let sDay = startDay;
    let n=nights;
    let period = {};
    let price = 0;
    // console.log('calculatePrice:');
    // console.log(n);

    while (n > 0){
        period = getPricePeriodByDates(prices, sDate, sDay, n);
        // console.log(period);

        if (period){
        //    sDate = new moment(sDate, 'YYYY-MM-DD').add(period.period.nights + (bookingInfo.minimum === 1 && n > 1 && period.period.nights > 1 ? 1 : 0), 'days');
            n = n - period.period.nights;
            sDate = new moment(sDate, 'YYYY-MM-DD').add(period.period.nights + (( n > 1 && period.period.nights > 1 && n < 7) || (bookingInfo.minimum > 1 && n === 1) ? 1 : 0), 'days');
            sDay = new moment(sDate).format('dddd').toLowerCase(); 
            // console.log(n);

           //subtract another night in some cases, not entirely correct
           if (( n > 1 && period.period.nights > 1 && n < 7) || (bookingInfo.minimum > 1 && n === 1)) n = n - 1;
        //    console.log(n);
        //    if (bookingInfo.minimum === 1 && n > 1 && period.period.nights > 1) n = n - 1;
        price = price + period.price;
        addChosenPeriod(period.period.description);
       } else {
        //    console.log("no period");
           n = 0;
       }

    }
    return price;
}

function getPricePeriodByDates(prices, startDate, startDay, nights){
    // console.log('getPricePeriods');
    // console.log(startDate);
    // console.log(startDay);
    // console.log(nights);
    //get the correct period using the start date
    let periods = prices.filter(p => startDate.isBetween(new moment(p.fromDate, 'YYYY-MM-DD'), new moment(p.toDate, 'YYYY-MM-DD'), undefined, '[]' ));
    //use the start day to determine the correct price
    let start = periods.filter(s => s.period.fromDay === startDay && s.period.nights <= nights);
    //if only one option remains, probably correct, return the found period and price
    if (start.length === 1 && nights > 1)  return start[0];
    //filter more by number of nights
    let byNights = periodByNights(start, nights);
    //only one option rwmains, return it
    if (byNights.length === 1) return byNights[0];

    //if minimum nights is 1, the above filters won't find one period option
    if (bookingInfo.minimum === 1) {
        let min = periods.filter(p => p.period.nights === 1);
        if (min.length === 1) return min[0];
    }
    return null;
}

function priceRangeOptions(prices, range, reset){
    // console.log(range);
    if (reset) resetBookingInfo();
    //determine minimum allowed nights
    minimumNights(prices);
    //check to see if the booking is far enough in the future
    if (rangeSelected(range, prices)) daysInAdvance(range) ;
    //get overview of bookable period ranges
    if (bookingInfo.periods.length === 0) periods(prices);
    //calculate the price for the chosen period
    if (rangeSelected(range, prices)) checkRangePeriod(prices, range);
    if(rangeSelected(range, prices) && range.range !== undefined && range.range.length > 0) bookingInfo.range = {startDate: range.range[0].startDate, endDate: range.range[0].endDate};
    return bookingInfo;
}

function getStatus(booking){
   return (<Stack spacing={2} direction="row">
        <Button variant={!booking.confirmed && !booking.paid50 && !booking.paid ? "contained" : "outlined"}>Initieel</Button>
        <Button variant={booking.confirmed ? "contained" : "outlined"}>Bevestigd</Button>
        <Button variant={booking.paid50 ? "contained" : "outlined"}>&#189; betaald</Button>
        <Button variant={booking.paid ? "contained" : "outlined"}>Betaald</Button>
   </Stack>)
  }

export {priceRangeOptions as validateRange, getStatus}