import {fetchBookingPrices} from "services/BookingService";
import { useQuery } from 'react-query';
import React, { useState, useEffect } from "react";
import { validateRange } from "./BookingFunctions";
import { makeStyles } from "@mui/styles";
import {getHtml} from "utils/StringFunctions";
import CurrencyFormat from 'react-currency-format';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import LoginRegister from "../login/LoginRegister";
import Button from "@mui/material/Button";
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import moment from 'moment/dist/moment.js';
import { formatDate } from "utils/DateFunctions";

const useStyles = makeStyles(styles);

export default function CheckRange(props)  {
const [cookies] = useCookies(['user','token']);
const houseId = props.houseId;
const  range = props.range;
let reset = props.reset;
const classes = useStyles();

const [fPrices, setFPrices]=useState([]); 
const [bookingInfo, setBookingInfo] = useState({});
const [state,setState]=useState();
const [showModal, setShowModal] = useState(false);
const [showOverview, setShowOverview] = useState(false);
const [showMLogin, setShowMLogin] = useState(false);
let startDate = '';
let endDate = '';

const bookingErrors = [{"no": 1,"error": "Ongeldige startdatum"}, 
{"no": 2,"error": "Ongeldige einddatum"},
{"no": 3,"error": "Minimum aantal dagen tussen boekdatum en startdatum: "},
{"no": 4,"error": "Minimum aantal nachten voor dit huis is "}];

const {prices, error, isError, isLoading } = useQuery('prices',  () => fetchBookingPrices(houseId), 
                                                            {onSuccess: (data) => 
                                                            { setFPrices(data)}});

                                                            
useEffect(() => {
        setBookingInfo(validateRange(fPrices, range, reset));
        setState({});
        if (reset) reset = false;
        console.log(range);
}, [props, fPrices, range]);

if(isLoading){
    return <div>Loading for houseId {houseId}...</div>
}
if(isError){
    return <div>No data found!</div>
}

function getErrorTekst (error){
    let errors = "";
    let optionsBool = false;
    if ((error && error.length > 0)){
        error.forEach(err => {
            let index = bookingErrors.findIndex(ber => ber.no === err.no);
            errors = errors +  bookingErrors[index].error + err.parameter + "<br/>";
            if (err === 1 || err === 2) optionsBool = true;
        });
        if (optionsBool) errors = errors + "zie de opties hierboven<br/>";
        errors = errors + "pas alstublieft uw keuze aan";
    }

    return errors;
}

function proceed(param){  
    if (param.status === 2 || (cookies.user && cookies.user !== 'undefined' )){
        setShowOverview(true);
    } else {
        if (param.status === 3) {
            if (isMobile) {
                setShowMLogin(false); }
            else {
                setShowModal(false); 
            }
        } else {
            if (isMobile) {
                setShowMLogin(true)}
            else {
                setShowModal(true);;
            }
        }
    }
}

function showDates(){
    if (bookingInfo.range !== undefined && bookingInfo.range.startDate && bookingInfo.range.endDate && new moment(bookingInfo.range.startDate).isAfter(new moment().add(3, 'days')) && new moment(bookingInfo.range.endDate).diff(new moment(bookingInfo.range.startDate)) > 0) {
        startDate = formatDate(bookingInfo.range.startDate, 1);
        endDate = formatDate(bookingInfo.range.endDate, 1) ;
        return true;
    } else {
        startDate = '';
        endDate = '';
        return false;}
}

function dutchDays(day){
    switch (day){
        case 'monday': return 'maandag';
        case 'tuesday': return 'dinsdag';
        case 'wednesday': return 'woensdag';
        case 'thursday': return 'donderdag';
        case 'friday': return 'vrijdag';
        case 'saturday': return 'zaterdag';
        case 'sunday': return 'zondag';

    }
}

return (
    <div className={classes.borderText}>
        {/* {fPrices.map(p => p.description)} */}
        {/* {range.range ? range.range[0].startDate.toString() : null} */}
        {/* <div>Minimum aantal nachten: {bookingInfo.minimum}</div> */}
        <h4>Opties:</h4>
        {bookingInfo.periods ? bookingInfo.periods.map(p => (
            <li key={p.id}>{p.description}: {p.fromDay ? <>{dutchDays(p.fromDay)} - {dutchDays(p.toDay)}, {p.nights} nachten - </> : <>{p.nights} nacht - </>} 
            {p.price > 0 ? <CurrencyFormat value={p.price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/> : ''}</li>
        )): null}
        <br/>Of een combinatie of veelvoud van de opties
        <br/><br/>{showDates() ? 'Van: ' + startDate + ' Tot: ' + endDate : 'Kies hiernaast een begin- en einddatum'}
        <div className={classes.price}><CurrencyFormat value={bookingInfo.price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/>{bookingInfo.price >0 && bookingInfo.error.length === 0 && '*'}</div>
        {(bookingInfo.chosen && bookingInfo.chosen.length > 0 ) ? bookingInfo.chosen.map(c=> (
            <li key={c.period}>{c.no} x {c.period} </li>
        )) : null }
        <p>{bookingInfo.price > 0 && bookingInfo.error.length === 0 &&'*exclusief bijkomende kosten'}</p>
        <div className={classes.error} dangerouslySetInnerHTML = {getHtml(getErrorTekst(bookingInfo.error))}></div>
        <div>
        <Button
            variant="contained"
            color="error"
            size="lg"
            onClick={() => proceed({status: 1})}
            className={classes.rButton}
            sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            disabled={!(bookingInfo.chosen && bookingInfo.chosen.length > 0 && bookingInfo.error.length === 0)}
        > 
        <i className="fas fa-play" />
            {cookies.user && cookies.user !== 'undefined' ? 'Reserveer' : 'Login & reserveer'}
        </Button>
        {showModal && <LoginRegister showModal={showModal} onModalClose={proceed}/>} 
        {showOverview && (<Navigate to="/overview" state={{ house: houseId, bookingInfo: bookingInfo, range: range }} />)} 
        {showMLogin && (<Navigate to="/login"  state={{url: "/house/" + houseId}}/>)}
        </div>
    </div>
)

}
