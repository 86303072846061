import properties from "properties";
import {getResponseWithKey, getWithKey, postResponseWithKey, postWithKey} from "./ApiKey";

const MAIL_API = 'mail/';
const ALL = 'getAllChatMessages/';
const ADDMESSAGE = 'addChatMessage/';
const NEW = 'getNewMessages'
const RND = 'setRandom';
const MAIL = 'getArchiveMessages'
const TYPES = 'getCustomisableMails';
const SETMAIL = 'setMailMessage';
const REPL = 'getPossibleReplacements';
const SENDBODY = 'sendMailWithBody';
const GETBODY = 'getBody';
const ANONMAIL = 'sendAnonMailWithBody';

// function chatsForBooking(token, bookingId){
//     return fetch(properties.apiUrl + MAIL_API + ALL + "?bookingId=" + bookingId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function chatsForBooking(token, bookingId){
    return getWithKey(properties.apiUrl + MAIL_API + ALL + "?bookingId=" + bookingId, token)
}

// function  newMessage(message, token){
//     console.log(message);
//     return fetch(properties.apiUrl + MAIL_API + ADDMESSAGE, { method: 'POST', 
//                                         body: JSON.stringify(message), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res => res.json())
//                     .then(json => {
//                         return json;
//                     }
//                     )
                    
//     }

function  newMessage(message, token){
    return postWithKey(properties.apiUrl + MAIL_API + ADDMESSAGE, JSON.stringify(message), token)
}

// function newChatsForBooking(token, bookingId, id){
//     return fetch(properties.apiUrl + MAIL_API + NEW + "?bookingId=" + bookingId + "&id=" + id
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function newChatsForBooking(token, bookingId, id){
    return getWithKey(properties.apiUrl + MAIL_API + NEW + "?bookingId=" + bookingId + "&id=" + id, token)
}

// function  setRandom(email){
//     return fetch(properties.apiUrl + MAIL_API + RND, { method: 'POST', 
//                                         body: JSON.stringify(email), 
//                                         headers:{ 'Content-Type': 'application/json'}})
//                     .then(res => res.json())
//                     .then(json => {
//                         return json;
//                     }
//                     )
                    
//     }

function  setRandom(email){
    return postWithKey(properties.apiUrl + MAIL_API + RND, JSON.stringify(email))
}

// function sendMailsForBooking(token, bookingId, houseId){
//     return fetch(properties.apiUrl + MAIL_API + MAIL + "?bookingId=" + bookingId + "&houseId=" + houseId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function sendMailsForBooking(token, bookingId, houseId){
    return getWithKey(properties.apiUrl + MAIL_API + MAIL + "?bookingId=" + bookingId + "&houseId=" + houseId, token)
}

// function getCustomisableMails(token, houseId){
//     return fetch(properties.apiUrl + MAIL_API + TYPES + "?houseId=" + houseId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function getCustomisableMails(token, houseId){
    return getWithKey(properties.apiUrl + MAIL_API + TYPES + "?houseId=" + houseId, token)
}

// function setCustomMailType(token, houseId, mail){
//     return fetch(properties.apiUrl + MAIL_API + SETMAIL + "?houseId=" + houseId, { method: 'POST', 
//                                         body: JSON.stringify(mail), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res => res.json())
//                     .then(json => {
//                         return json;
//                     }
//                     )
// }

function setCustomMailType(token, houseId, mail){
    return postWithKey(properties.apiUrl + MAIL_API + SETMAIL + "?houseId=" + houseId, JSON.stringify(mail), token)
}


// function getReplacements(token){
//     return fetch(properties.apiUrl + MAIL_API + REPL, 
//             {headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//         .then(res => res.json())
//         .then(json => {
//         return json;
//         }
//     )
// }

function getReplacements(token){
    return getWithKey(properties.apiUrl + MAIL_API + REPL, token)
}

// function  sendMailWithBody(token, bookingId, houseId, typeId, mail){
//     return fetch(properties.apiUrl + MAIL_API + SENDBODY + "?bookingId=" + bookingId + "&houseId=" + houseId + "&typeId=" + typeId , { method: 'POST', 
//                                         body: JSON.stringify(mail), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res => res.json())
//                     .then(json => {
//                         return json;
//                     }
//                 )
// }

function  sendMailWithBody(token, bookingId, houseId, typeId, mail){
    return postWithKey(properties.apiUrl + MAIL_API + SENDBODY + "?bookingId=" + bookingId + "&houseId=" + houseId + "&typeId=" + typeId, JSON.stringify(mail), token)
}

// function getMailBody(bookingId, houseId, typeId, token){
//     return fetch(properties.apiUrl + MAIL_API + GETBODY + "?bookingId=" + bookingId + "&houseId=" + houseId + "&typeId=" + typeId ,
//             {headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//         .then(res => res.json())
//         .then(json => {
//         return json;
//         }
//     )
// }

function getMailBody(bookingId, houseId, typeId, token){
    return getWithKey(properties.apiUrl + MAIL_API + GETBODY + "?bookingId=" + bookingId + "&houseId=" + houseId + "&typeId=" + typeId, token)
}

function  sendAnonMailWithBody(houseId, subject, from, mail){
    return postWithKey(properties.apiUrl + MAIL_API + ANONMAIL + "?houseId=" + houseId + "&subject=" + subject + "&from=" + from, JSON.stringify(mail))
}

export {chatsForBooking, newMessage, newChatsForBooking, setRandom, sendMailsForBooking, getCustomisableMails, setCustomMailType, getReplacements, sendMailWithBody, getMailBody, sendAnonMailWithBody}