import React, { Component, useState, useEffect, useRef } from "react";
import { withCookies, Cookies } from "react-cookie";
import { chatsForBooking, newMessage, newChatsForBooking } from "services/CommunicationService.js";
import "./Chat.css"


function Chat(props){

    const bottomRef = useRef(null);
    const booking = props.booking;
    let cookies = props.cookies;
    const token = cookies.get('token');
    const user = cookies.get('userId');
    const [chat, setChat] = useState([]);
    const [message, setMessage] = useState('');
    const chatRef = useRef(chat);
    chatRef.current = chat;

    useEffect(() => {
        
        const fetchData = async () => {
            try {
                let messages = await chatsForBooking(token, booking.id);
                setChat(messages);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    },[booking])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [chat, booking]);

    useEffect(() => {
        
     const interval = setInterval(() => {
        console.log(chatRef.current);
        const chatId = chatRef.current.length > 0 ? chatRef.current.slice(-1)[0].id : 0;
        console.log(chatId);
        try {
            newChatsForBooking(token, booking.id, chatId).then(res => {
            res.map(newItem => {
                if (!chatRef.current.some(item => item.id === newItem.id))
                setChat(chat => [...chat, newItem]);
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, 10000);
    return () => clearInterval(interval);
    }, []);

    function getContainerClass(userId){
        if (Number(userId) === Number(user)) {
            return "container";
        } else {
            return "container darker";
        }
    }

    function onMessageChange(e) {
        setMessage(e.target.value);
      }

    function addNewMessage(e){
        e.preventDefault();
        const addMessage = {id: 0
            ,houseId: booking.houseId
            ,bookingId: booking.id
            ,userId: user
            ,message: message
            ,ownerId: 0
            ,dateInserted: ''
            };

        newMessage(addMessage, token).then(res => {
            res.map(newItem => {
            setChat(chat => [...chat, newItem]);
            });
        });
        setMessage('');
    }


    return (
        <form onSubmit={addNewMessage}>
            <div className="div-height">
                {chat.length >0 && chat[0].bookingId === booking.id && chat.map((c, idx) => (
                    <div key={idx} className={getContainerClass(c.userId)}>
                        <p>{c.message}</p>
                        <span className="time-left">{c.dateInserted}</span>
                    </div>
                ))}
                <div ref={bottomRef} className="div-bottom"></div>
            </div>
                <input
                    type="text"
                    name="message"
                    value={message}
                    className="input-message"
                    placeholder="Bericht"
                    maxLength="200"
                    onChange={onMessageChange.bind(this)}
                    />
                    <button disabled={message.length === 0} type="submit">&gt;</button> 
        </form>
    )
}

export default withCookies(Chat);