export default function NumberFunctions (){

}

function isEven(n) {
    return n % 2 === 0;
 }
 
 function isOdd(n) {
    return Math.abs(n % 2) === 1;
 }
 function isEvenOrOdd(n) {
     if (n % 2 === 0) {
        //  console.log(0);
         return 0;
     } else {
        // console.log(1);
         return 1;
     }
 }

export {isEven, isOdd, isEvenOrOdd}