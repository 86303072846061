/*eslint-disable*/
import React, { useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// react components for routing our app without refresh
import { useNavigate } from "react-router-dom";

// @mui/material components
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import { Apps, AccountCircle } from "@mui/icons-material";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import LoginRegister from "../login/LoginRegister";
import MLoginRegister from "../login/MLoginRegister";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useCookies } from 'react-cookie';
import ChangePassword from '../login/ChangePassword';
import {isMobile} from 'react-device-detect';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showCPModal, setShowCPModal] = useState(false);
  const [cookies, setCookie] = useCookies(['user','token']);
  let navigate=useNavigate();

function isShowModal(param){  
  if (param.status === 1) {
     setShowModal(true); 
    } else {
      setShowModal(false); 
    }

}

function isShowCPModal(param){  
     setShowCPModal(param); 
}

const logout =() =>{
  setCookie('user', undefined, { path: '/', secure: true, sameSite: 'none'});
  setCookie('token', undefined, { path: '/', secure: true, sameSite: 'none'});
  setCookie('bookings', undefined, { path: '/', secure: true, sameSite: 'none'});
  setCookie('houses', undefined, { path: '/', secure: true, sameSite: 'none'});
  setCookie('userId', undefined, { path: '/', secure: true, sameSite: 'none'});
  navigate("/");
}

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Account"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={AccountCircle}
          dropdownList={[
            !cookies.user || cookies.user === 'undefined'?

              !isMobile ?
                <a href="#" className={classes.dropdownLink} onClick={() => isShowModal({status : 1})}>
                  Inloggen/Registreren
                </a>

              :
                  <Link className={classes.dropdownLink}  to="login">Login/Registreren</Link>
            :
            <div className={classes.dropdownLink} onClick={logout}>
              Uitloggen {cookies.user}
            </div>
            ,
            <a href="#" className={classes.dropdownLink} onClick={() => isShowCPModal(true)}>
              Wachtwoord
            </a> ,
          ]}
        />
      </ListItem>
      {showModal && <LoginRegister showModal={showModal} onModalClose={isShowModal}/>} 
      {showCPModal && <ChangePassword loggedIn={cookies.token && cookies.token !== 'undefined'} onChangePasswordClose={isShowCPModal}/>} 
    </List>
  );
}
