import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import GridContainer from "components/Grid/GridContainer.js";
import CompactGridContainer from "components/Grid/CompactGridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CurrencyFormat from 'react-currency-format';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
import {useLocation} from "react-router-dom";
import {fetchHouse, fetchImageUrl, fetchTermsPdfUrl} from "services/HouseService";
import {fetchAdditionalCosts, book} from "services/BookingService";
import { isEvenOrOdd } from "utils/NumberFunctions";
import moment from 'moment/dist/moment.js';
import { withCookies } from "react-cookie";
import SimpleModal from "utils/SimpleModal";
import { checkDiscountCode } from "services/BookingService";
import TextField from "@mui/material/TextField";
import MenuItem  from "@mui/material/MenuItem";
import { formatDate } from "utils/DateFunctions";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { ClassRounded } from "@mui/icons-material";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles(styles);

 const BookingOverview = props =>  {

    let cookies = props.cookies;
    const token = cookies.get('token');
    const bookings = cookies.get('bookings');
    let location = useLocation();
    const navigate = useNavigate();
    const houseId =(location.state !== undefined && location.state !== null) ? location.state.house : null;
    const  bookingInfo = (location.state !== undefined && location.state !== null)  ? location.state.bookingInfo : null;
    const range = (location.state !== undefined && location.state !== null) ? location.state.range : null;
    const {data: house, error: houseError, isLoading: houseIsLoading } = useQuery(['house', houseId], () =>  fetchHouse(houseId), {enabled: houseId != null});
    const {data: additional, error: additionalError, isLoading: additionalIsLoading, isSuccess: additionalsuccess } = useQuery(['additional', houseId], () =>  fetchAdditionalCosts(houseId, token), {enabled: houseId != null});
    const classes = useStyles();
    const [checkedAdditionals, setCheckedAdditionals] = useState([]);
    const [price, setPrice] = useState(0);
    const [extra, setExtra] = useState({agreedConditions: false, remarks : ""});
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const bookingSuccess = {title: 'Reservering gelukt', description: 'Uw reservering is gelukt.<br/> U krijgt zo spoedig mogelijk bericht van de verhuurder over de verdere afhandeling'};
    const bookingFail = {title: 'Reservering mislukt :-(', description: 'Iemand was u net voor of er is iets mis gegaan.<br/> Probeer het later nog een keer, of neem contact op met de beheerder van de site'};
    const [reservationSucceeded, setReservationSucceeded]= useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discount, setDiscount] = useState({});
    const [persons, setPersons] = useState({adults: 0, kids: 0, pets: 0});
    const [maxPersons, setMaxPersons] = useState([]);
    const [ageReduced, setAgeReduced] = useState(0);
    const [maxPets, setMaxPets] = useState([]);

    //return true, only when all queries are done
    const isLoading = houseIsLoading || additionalIsLoading;
    //return true, only when all queries run successfully
    const error = houseError || additionalError;

    useEffect(() => {
        if (persons.adults > 0 || persons.kids > 0) {
            getPrice();
        } 

      }, [persons]);

    useEffect(() => {
        if(additional && price === 0 && house){
            getPrice();
            getMaxPersons();
            getMaxPets();
        } 
    }, [additional, house]);

    if(isLoading){
        return <div>Loading for houseId {houseId}...</div>
    }
    if(error){
        return <div>No data found!</div>
    }

    function getTileClass(id) {
        if (isEvenOrOdd(id)) return classes.tile0;
        return classes.tile1;
     }

     function checkAdditional(event){
        const additonalId = Number(event.target.value);
        let checked = checkedAdditionals;
        event.target.checked ?  checked.push(additonalId) : checked.splice(checked.indexOf(additonalId), 1);
        setCheckedAdditionals(checked);
        getPrice();
    }

     function getPrice(init){
        // console.log('getPrice');
        let calculatedPrice = bookingInfo.price;
        const days = moment(bookingInfo.range.endDate).diff(moment(bookingInfo.range.startDate), "days");

        function calc(amount, pp, ppt, reduced){
            if (pp === true){
                let ret = 0;
                if (persons.adults > 0){
                    ret += persons.adults * amount;
                }
                if (persons.kids > 0){
                    ret += persons.kids * (reduced !== null ? reduced : amount)
                }
                return ret;
            }
            if (ppt === true){
                let ret = 0;
                if (persons.pets > 0){
                    ret += persons.pets * amount;
                }
                return ret;
            }
            return amount;
        }

        additional.length > 0 && additional.forEach(a => {
            if (a.additionalCost.perStay === true && (a.required === true || checkedAdditionals.indexOf(a.id) >= 0)) calculatedPrice += calc(a.amount, a.additionalCost.perPerson, a.additionalCost.perPet, a.amountReduced);
            if (a.additionalCost.perDay === true && (a.required === true || checkedAdditionals.indexOf(a.id) >= 0)) calculatedPrice += (days * calc(a.amount, a.additionalCost.perPerson, a.additionalCost.perPet, a.amountReduced));
            if (a.additionalCost.perWeek === true && (a.required === true || checkedAdditionals.indexOf(a.id) >= 0)) calculatedPrice += (Math.ceil(days/7) * calc(a.amount, a.additionalCost.perPerson, a.additionalCost.perPet, a.amountReduced));
            if (a.additionalCost.perMonth === true && (a.required === true || checkedAdditionals.indexOf(a.id) >= 0)) calculatedPrice += (Math.ceil(days/30) * calc(a.amount, a.additionalCost.perPerson, a.additionalCost.perPet, a.amountReduced));

            return calculatedPrice;
        }) 
        if (!init && discount && discount.amount > 0) calculatedPrice -= discount.amount;
        setPrice(calculatedPrice);
        return calculatedPrice;
     }

     function getMaxPersons(){
        let maxPer = 0;
        const maxPers = [];
        // house.features.map(f => console.log(f.feature));
        const maxPerList = house.features.filter(f => f.feature === 'personen');
        if (maxPerList.length > 0) maxPer = maxPerList[0].value.substr(0,maxPerList[0].value.indexOf(','));

        for (var i = 0; i < Number(maxPer) + 1; i++) {
            maxPers.push(i);
        }
        setMaxPersons(maxPers);
        //kid age specified?
        // console.log('additional');
        // console.log(additional);
        const localAdd = additional.filter(a => a.ageReduced !== null && a.ageReduced > 0);
        if (localAdd.length > 0) setAgeReduced(Number(localAdd[0].ageReduced));
     }

     function getMaxPets(){
        // console.log('maxpets');
        let maxPet = 0;
        const maxPets = [];
        // house.features.map(f => console.log(f.feature));
        const maxPetList = house.features.filter(f => f.feature === 'huisdieren');
        // console.log(maxPetList);
        if (maxPetList.length > 0) maxPet = maxPetList[0].value.match(/(\d+)/);
        // console.log(maxPet[0]);

        for (var i = 0; i < Number(maxPet[0]) + 1; i++) {
            maxPets.push(i);
        }
        setMaxPets(maxPets);
        // console.log('additional');
        // console.log(additional);

     }

     function adultsChange(event){
        const adults = {adults: event.target.value};
        setPersons(persons => ({
            ...persons,
            ...adults
        }))
     }

     function kidsChange(event){
        const kids = {kids: event.target.value};
        setPersons(persons => ({
            ...persons,
            ...kids
        }))
     }

     function petsChange(event){
        const pets = {pets: event.target.value};
        setPersons(persons => ({
            ...persons,
            ...pets
        }))
     }

     function setAgreed(){
        const agreedConditions = {agreedConditions: !extra.agreedConditions};
        setExtra(extra => ({
            ...extra,
            ...agreedConditions
          }));
     }

     function setRemarks(event){
        const remarks = {remarks: event.target.value};
        setExtra(extra => ({
            ...extra,
            ...remarks
        }));
     }

     function newBooking(){
        if (token === undefined) navigate('/house/'+ houseId);
        const bookingAdditionals = [];
        additional.filter(a => a.required === true || checkedAdditionals.indexOf(a.id) >= 0).map(b => {
            bookingAdditionals.push({houseAdditionalCosts: b, amountCustom: 0});
        })
        const booking = {id: 0
                        ,houseId: houseId
                        ,userId: 0
                        ,description: ""
                        ,fromDate: formatDate(bookingInfo.range.startDate, 2)
                        ,toDate: formatDate(bookingInfo.range.endDate, 2)
                        ,totalPrice: price
                        ,remarks: extra.remarks
                        ,bookingAdditionalCosts: bookingAdditionals
                        ,discountId: discount.id
                        ,adults: persons.adults
                        ,kids: persons.kids
                        ,pets: persons.pets
                        };

        book(booking, token).then(res => {
            if (res.ok) {
                setReservationSucceeded(true);
                // console.log(reservationSucceeded);
                setShowModalSuccess(true);
                addBookingToCookie();
            } else {
                setShowModalFail(true);
            }
        });
     }

     function addBookingToCookie(){
        bookings.bookingsFuture = bookings.bookingsFuture + 1;
        cookies.set('bookings', bookings, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
     }

     function onModalClose(){
        // console.log('close');
        // console.log(reservationSucceeded);
        setShowModalSuccess(false);
        setShowModalFail(false);

        if (reservationSucceeded) {
            // console.log('nav');
            navigate('/house/'+ houseId, {state: {reset: true}});
        }
     }

     function onDiscountCodeChange(e) {
        setDiscountCode(e.target.value);
        if (e.target.value.length === 0) {
            getPrice(true);
            setDiscount({});
        }
      }

      
    async function checkCouponCode() {
        const localPrice = getPrice(true);
        // console.log(localPrice);
        if (discountCode.length ===0) return;
        const json = await checkDiscountCode(discountCode, houseId, token);
        setDiscount(json);
        // console.log(discount);
        if (json !== undefined && (json.percentage === null || json.percentage ===0) && json.amount > 0) setPrice(localPrice - json.amount );
        if (json !== undefined && json.percentage > 0 && (json.amount === null || json.amount === 0)) setPrice(localPrice - (localPrice * json.percentage/100) );
    }

    return (
        <div className={classes.startTiles}>
            {house !== undefined ?
            <div className={getTileClass(house.id)} key={house.id}>   
                <GridContainer spacing={2} >
                    <GridItem xs={isMobile ? 12 : 4} sm={isMobile ? 12 : 4} md={4}> 
                        <img  src={fetchImageUrl(house.id, house.houseImages[0].path)} alt="First Pic" className={classes.image}/>
                    </GridItem>
                    <GridItem xs={isMobile ? 12 : 8} sm={isMobile ? 12 : 8} md={8}>
                        <div className={classes.resTitle}>
                                Uw reservering voor {house.name} in {house.houseAddress.city}, {house.houseAddress.country}
                        </div>
                        <div className={classes.resText}>
                            <CompactGridContainer spacing={2} >
                                <GridItem xs={isMobile ? 6 : 4} sm={4} md={4}>
                                    Begindatum: 
                                </GridItem>
                                <GridItem xs={isMobile ? 6 : 4} sm={4} md={4}>
                                    {formatDate(bookingInfo.range.startDate, 1)}
                                </GridItem>
                            </CompactGridContainer>
                            <CompactGridContainer spacing={2} >
                                <GridItem xs={isMobile ? 6 : 4} sm={4} md={4}>
                                    Einddatum: 
                                </GridItem>
                                <GridItem xs={isMobile ? 6 : 4} sm={4} md={4}>
                                    {formatDate(bookingInfo.range.endDate, 1)}
                                </GridItem>
                            </CompactGridContainer>
                        </div>
                        <div className={isMobile ? classes.mPriceTitle : classes.priceTitle}>
                            Huur: <CurrencyFormat value={bookingInfo.price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/>
                        </div>
                        <div>
                        <h4 className={classes.headerText}>Aantal personen:</h4>
                            <TextField
                                size="small"
                                label={"Volwassenen" + (ageReduced > 0 ? ' en kinderen ouder dan ' + ageReduced + ' jaar' : '')}
                                sx={{ width: (ageReduced > 0 ? 300 : 200)}}
                                select
                                value={persons.adults}
                                onChange={adultsChange}
                            >
                                {maxPersons.map((max) => (
                                <MenuItem key={max} value={max}>
                                    {max}
                                </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                size="small"
                                label={"Kinderen" + (ageReduced > 0 ? ' jonger dan ' + ageReduced + ' jaar' : '')}
                                sx={{ width: 200 }}
                                select
                                value={persons.kids}
                                onChange={kidsChange}
                            >
                                {maxPersons.map((max) => (
                                <MenuItem key={max} value={max}>
                                    {max}
                                </MenuItem>
                                ))}
                            </TextField>
                            {maxPets.length > 1 && <TextField
                                size="small"
                                label={"Huisdieren"}
                                sx={{ width: 200 }}
                                select
                                value={persons.pets}
                                onChange={petsChange}
                            >
                                {maxPets.map((max) => (
                                <MenuItem key={max} value={max}>
                                    {max}
                                </MenuItem>
                                ))}
                            </TextField>}
                        </div>
                        <div>
                            <h4 className={classes.headerText}>Opties, verplicht en optioneel:</h4>
                        
                        {additional.length > 0 ? additional.sort((a, b) => a.id > b.id ? 1 : -1).map((a, idx) => (
                             <CompactGridContainer spacing={2} key={idx}>
                                <GridItem xs={isMobile ? 8 : 5} sm={5} md={5}>
                                
                                    <div className={classes.resText} >
                                        <label>
                                            <input type="checkbox" onChange={checkAdditional} value={a.id} defaultChecked={a.required} readOnly={a.required} disabled={a.required}/>
                                            {isMobile ? a.additionalCost.description.substr(0,20) : a.additionalCost.description}
                                        </label>
                                    </div>

                                </GridItem>
                                <GridItem className={classes.resText} xs={isMobile ? 4 : 3} sm={3} md={3}>
                                    <CurrencyFormat value={a.amount} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/>
                                    {!isMobile && a.amountReduced !== null ? (" (<" + a.ageReduced + " €" + a.amountReduced + ")") : ""}
                                </GridItem>
                                <GridItem className={classes.resText} xs={isMobile ? 0 : 4} sm={4} md={4}>
                                    {!isMobile && a.additionalCost.perStay === true ?'per verblijf' : ''}
                                    {!isMobile && a.additionalCost.perDay === true ? 'per dag' : ''}
                                    {!isMobile && a.additionalCost.perWeek === true ? 'per week' : ''}
                                    {!isMobile && a.additionalCost.perMonth === true ? 'per maand' : ''}
                                    {!isMobile &&a.additionalCost.perPerson === true ? '/per persoon' : ''}
                                    {!isMobile &&a.additionalCost.perPet === true ? '/per huisdier' : ''}
                                </GridItem>
                            </CompactGridContainer>
                        )
                        ): ""}
                        
                        </div>
                        <GridContainer spacing={2} paddingTop="2%">
                            <GridItem xs={isMobile ? 6 : 4} sm={4} md={4}>
                                <div>
                                    <input
                                    type="text"
                                    name="discountCode"
                                    className={classes.discountInput}
                                    placeholder="Kortingscode"
                                    maxLength="20"
                                    onChange={onDiscountCodeChange
                                    .bind(this)}/>
                                </div>
                                <div className={classes.discountText}>
                                {discount.message}{discount.message && (discount.percentage != null || discount.amount != null) ? discount.percentage !== null ? ", " + discount.percentage + "% korting": ", €" + discount.amount + " korting" : ""}
                                </div>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="error"
                                    className="check-btn"
                                    onClick={checkCouponCode}
                                    disabled={discountCode.length === 0} >Toepassen
                                </Button>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className={isMobile ? classes.mPriceTitle : classes.priceTitle}>
                                Totaal bedrag: <CurrencyFormat value={price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/>
                            </GridItem>
                            <GridItem className={classes.resTextMargin} marginTop="1%">
                                Als u akkoord gaat met de voorwaarden en op de reserveer knop klikt,<br/> gaat u een bindende overeenkomst aan met de verhuurder.<br/>
                                De verhuurder bekijkt of het vakantiehuis inderdaad in de gewenste periode beschikbaar is.<br/>
                                Als alles klopt, krijgt u vervolgens een bevestiging per email.<br/>
                                In deze email zult u tevens de betaalopties aantreffen.
                            </GridItem>
                            <GridItem className={classes.resTextMargin} marginTop="1%">
                                <label>
                                <input type="checkbox" onChange={setAgreed} value={extra.agreedConditions}/>
                                Ik ga akkoord met de </label>
                                <a href={fetchTermsPdfUrl(house.id)} target="_blank" download>voorwaarden</a>
                            </GridItem>
                            <GridItem className={classes.resTextMargin} marginTop="1%">
                                <label className={classes.labelText}>Eventuele opmerkingen:</label>
                                <textarea name="remarks"
                                        onChange={setRemarks}
                                        value={extra.remarks}
                                        cols={isMobile ? 35 : 40}
                                        rows={5}
                                        wrap="hard"/>
                            </GridItem>
                            <GridItem>
                                <Button
                                variant="contained"
                                color="error"
                                size="lg"
                                onClick={() => newBooking()}
                                className={classes.lButton}
                                disabled={!(extra.agreedConditions && persons.adults + persons.kids >0) || token === 'undefined'}
                                >
                                <i className="fas fa-play" />
                                    {token&& token !== 'undefined' ? 'Reserveer' : 'Sessie verlopen, kies opnieuw'}
                                </Button> 
                            </GridItem>
                            {(extra.agreedConditions && persons.adults + persons.kids === 0) ? <div className={classes.discountText}>Selecteer nog het aantal personen alstublieft</div> : ''}
                        </GridContainer>
                    </GridItem>

                </GridContainer>

                {showModalSuccess && <SimpleModal title={bookingSuccess.title} description={bookingSuccess.description} onModalClose={onModalClose}/>}
                {showModalFail && <SimpleModal title={bookingFail.title} description={bookingFail.description} onModalClose={onModalClose}/>}
            </div>
            : ''}
        </div>
        
    )
}

const Overview = withCookies(BookingOverview);
export default Overview;