import React, { useState, useRef, useMemo, useEffect }from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {useQuery} from 'react-query';
import { withCookies } from "react-cookie";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import MailPopup from "./MailPopup.js";
import { getMailBody, sendMailWithBody } from "services/CommunicationService.js";

const useStyles = makeStyles(styles);

function NotifyButtons(props) {

  const classes = useStyles();
  let cookies = props.cookies;
  const token = cookies.get('token');
  const houseId = props.houseId;
  const [booking, setBooking] = useState();
  const [customMail, setCustomMail] = useState({type: 0, body: ""});
  const [showMailModal, setShowMailModal] = useState(false);
  // const {data: mailBody, error: mailBodyError, isLoading: mailBodyLoading } = useQuery(['mailTypes', houseId, token], () =>  getCustomisableMails(token, houseId));

  useEffect(() => {
        setBooking(props.booking);
  }, [props.booking]);

useEffect(() => {
    if (customMail.type !== 0) setShowMailModal(true);
}, [customMail]);

function isShowMailModal(param){  
  setShowMailModal(param); 
}

function saveBody(newBody, type){
  console.log(newBody);
  console.log(type);
  const mail = {id: 0
    ,type: type
    ,body: newBody
    };

    sendMailWithBody(token, booking.id, houseId, type, mail).then(res => {
        console.log(res);
    });
}

function getBody(typeId){
    getMailBody(booking.id, houseId, typeId, token).then(res => {
      console.log(res);
      setCustomMail({type: typeId, body: res.body});
  });
}


return (<Stack spacing={2} direction="row">
    {booking !== undefined &&
        <div>
            {props.owner && 
            <div>
              <div>
                <Button onClick={() => getBody(7)} variant={booking.paid50 ? "contained" : "outlined"}>Betaal helft</Button>
                <Button onClick={() => getBody(8)} variant={booking.paid ? "contained" : "outlined"}>Betaal totaal</Button>
                <Button onClick={() => getBody(9)} variant={"outlined"}> Inchecken</Button>
              </div>
              <div>
              Zet status:
                <div>
                  <Button onClick={() => getBody(3)} variant={booking.confirmed ? "contained" : "outlined"}>Goedkeuren</Button>
                  <Button onClick={() => getBody(4)} variant={booking.cancelledByClient || booking.cancelledByOwner ? "contained" : "outlined"}>Afkeuren</Button>
                  <Button onClick={() => getBody(3)} variant={booking.paid50||booking.paid ? "contained" : "outlined"}>Helft Betaald</Button>
                  <Button onClick={() => getBody(4)} variant={booking.paid ? "contained" : "outlined"}>Betaald</Button>
                </div>
              </div>
            </div>
            }
            {!props.owner && <Button variant={booking.paid50 ? "contained" : "outlined"}>Annuleren</Button>}
            {showMailModal && <MailPopup id={customMail.id} body={customMail.body} onMailPopupClose={isShowMailModal} newBody={saveBody} type={customMail.type} showSend={true}/>}
        </div>}
</Stack>)
}

export default withCookies(NotifyButtons);