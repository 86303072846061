import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import { withCookies } from "react-cookie";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { sendMailsForBooking } from "services/CommunicationService.js";
import moment from 'moment/dist/moment.js';
import Typography from '@mui/material/Typography';
import {useQuery} from 'react-query';
import MailPopup from "views/shared/MailPopup.js";

const useStyles = makeStyles(styles);


function Mails(props){
    const classes = useStyles();
    let cookies = props.cookies;
    const token = cookies.get('token');
    const houseId = props.houseId;
    const bookingId = props.bookingId;
    const {data: mails, error: mailsError, isLoading: mailsLoading } = useQuery(['mails', bookingId, houseId, token], () =>  sendMailsForBooking(token, bookingId, houseId));
    const [showMailModal, setShowMailModal] = useState(0);

    if(mailsLoading){
        return <div>Loading for houseId {houseId}...</div>
    }
    if(mailsError){
        return <div>No data found!</div>
    }

    function isShowMailModal(param){  
      setShowMailModal(param); 
    }

    function saveBody(newBody){
      // console.log(newBody);
    }
    return (
        <div className={classes.labelAbove}>
            <div>
            <Typography className={classes.subTitle}>{mails.length >0 ? "Verstuurde mails aan u en " + mails[0].receiverEmail : "Geen verstuurde mails"}</Typography>
                  <TableContainer component={Paper} className={classes.gridHeight}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead >
                      <TableRow className={classes.tableRow}>
                        <TableCell>Datum</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {mails.length >0  && mails.map((m, idx) => (
                        <TableRow
                          key={idx}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={classes.tableRow}
                          onClick={() => setShowMailModal(m.id)}
                          style={{cursor:'pointer'}}
                        >
                          <TableCell component="th" scope="row">
                          {moment(m.sendDate).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell>{m.mail.type}</TableCell>
                          {showMailModal === m.id && <MailPopup body={m.body} onMailPopupClose={isShowMailModal} readOnly={true} newBody={saveBody}/>} 
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default withCookies(Mails);