import React, { useState, useEffect, useRef } from "react";
import { useQuery } from 'react-query';
import { withCookies } from "react-cookie";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import { setActive } from "../../../services/HouseService.js";
import TabPanel from "../../shared/TabPanel.tsx";
import MailTypes from "./MailTypes.js";

const useStyles = makeStyles(styles);

function General(props){
    let cookies = props.cookies;
    const token = cookies.get('token');
    const classes = useStyles();
    const house = props.house;
    const [checked, setChecked] = React.useState(house.active);

    useEffect(() => {
      if (house.active !== checked){
        setActive(house.id, checked,  token).then(res => {
            if (res) {
                console.log("Gelukt");
            } else {
                console.log("Mislukt");
            }
        })
        house.active = checked;
      }
    },[checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

  };

    return (
        <TabPanel value={props.value} index={props.index}>
          <div className={classes.tile}>
            <GridContainer spacing={2} >
                <GridItem xs={12} sm={12} md={12}> 
                <Stack direction="row" spacing={1} alignItems="center" className={classes.container}> 
               <Typography>In de Verhuur</Typography>
                        <Switch
                            checked={checked}
                            onChange={handleChange}

                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        </Stack>
                 </GridItem>
            </GridContainer>
            <GridContainer spacing={2} >
                <GridItem xs={12} sm={12} md={12}> 
                    <MailTypes houseId={house.id}/>
                 </GridItem>
            </GridContainer>
          </div>
        </TabPanel>
    )
}

export default withCookies(General);