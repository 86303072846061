import React from "react";
import {fetchAllHouses, fetchImageUrl} from "services/HouseService";
import {getHtml} from "utils/StringFunctions";
import { isEvenOrOdd } from "utils/NumberFunctions";
// @mui/material components
import { makeStyles } from "@mui/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CurrencyFormat from 'react-currency-format';
// import Button from "components/CustomButtons/Button.js";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import {useQuery} from 'react-query';
// import {MobileView, BrowserView} from 'react-device-detect';
import beds from '../../assets/img/bed.png';
import persons from '../../assets/img/persons.png';
import bathrooms from '../../assets/img/shower.png';
import internet from '../../assets/img/wi-fi.png';

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

// const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function HouseOverview(props) {
    const classes = useStyles();
    // const [houses, fetchHouses] = useState([]);
    let counter = 0;
    const houseLink = "house/";

    // useEffect(() => {
    //     fetchAllHouses().then (res => {
    //         fetchHouses (res);
    //     });
    // }, []);

    const {data, error, isError, isLoading } = useQuery('houses', fetchAllHouses) 
    // first argument is a string to cache and track the query result
    if(isLoading){
        return <div>Loading...</div>
    }
    if(isError){
        return <div>Error! {error.message}</div>
    }

    function getTileClass() {
        counter++;
        if (isEvenOrOdd(counter)) return classes.tile0;
        return classes.tile1;
     }

     function getFeatureImg(feat){
        if (feat === "slaapkamers") return (<img  src={beds} alt="beds" className={classes.featureImg}/> )
        if (feat === "badkamers") return (<img  src={bathrooms} alt="bathrooms" className={classes.featureImg}/>)
        if (feat === "personen") return (<img  src={persons} alt="persons" className={classes.featureImg}/>)
        if (feat === "internet")  return (<img  src={internet} alt="internet" className={classes.featureImg}/>)

     }
     function getFeaturePart(feat){
        return feat.substr(0, feat.indexOf(",")); 
     }

    return (
        <div className={classes.startTiles}>
            {data.map((house) => (
                
            <div className={getTileClass(house.id)} key={house.id}>               
                <GridContainer spacing={2} >
                    <GridItem xs={12} sm={4} md={6}> 
                        <h3 className={classes.description}>
                                {house.name} in {house.houseAddress.city}, {house.houseAddress.country}
                        </h3>
                        <img  src={fetchImageUrl(house.id, house.houseImages[0].path)} alt="First Pic" className={classes.image}/>
                        {/* <GridItem xs={12} sm={12} md={6} >
                        {house.prices ? house.prices.map((price, idx) => (
                            <h4 className={classes.description} key={idx}>

                                <ul>{price.period} vanaf <CurrencyFormat value={price.price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/></ul>

                            </h4>
                        )
                        ): null}
                        </GridItem> */}
                    </GridItem>
                    <GridItem xs={12} sm={8} md={6}> 
                    <h5 className={classes.description}>
                        <div dangerouslySetInnerHTML = {getHtml( house.descriptionShort)}></div>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer spacing={2} columns={{ xs: 12, sm: 12, md: 12 }} direction="row">
                                {house.features.filter(feat => feat.frontPageInd === true).sort((a,b) =>  a.seqNo-b.seqNo ).map((filteredFeat, idx2) => (

                                                <GridItem xs={3} sm={3} md={3} key={idx2}>
                                                {getFeatureImg(filteredFeat.feature)}<div className={classes.featureVal}>{getFeaturePart(filteredFeat.value)}</div>
                                                </GridItem>
                                
                                ))}
                            </GridContainer>
                        </GridItem>
                    </h5> 
                    <GridItem xs={12} sm={12} md={6} >
                        {house.prices ? house.prices.map((price, idx) => (
                            <h4 className={classes.description} key={idx}>

                                <ul>{price.period} vanaf <CurrencyFormat value={price.price} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/></ul>

                            </h4>
                        )
                        ): null}
                        </GridItem>
                        <Link to={houseLink + house.id} >
                            <Button
                                variant="contained"
                                color="error"
                                // size="lg"
                            > 
                            <i className="fas fa-play" />
                            Check beschikbaarheid en prijzen
                            </Button>
                        </Link>
                    </GridItem>
                </GridContainer>
            </div>
            ))}
        </div>
    )
}