import React, { useState, useRef, useMemo, useEffect }from "react";
import backButton from '../../assets/img/back.png';
import { useCookies } from 'react-cookie';
import JoditEditor from 'jodit-react';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

export default function MailPopup(props) {

  const editor = useRef(null);
  const [content, setContent] = useState('');

  useEffect(() => {
        setContent(props.body);
  }, [props.body]);

  const config = useMemo(() =>
      ({  iframe: true,
        editHTMLDocumentMode: true,
          readonly: props.readOnly, // all options from https://xdsoft.net/jodit/docs/,
          width: 'auto',
          height: 'auto',
          minHeight: 300,
      }),
      []
  );

  function isShowMailPopup(){  
    closeMailModal();  
} 

function closeMailModal() {
    props.onMailPopupClose(false);
};

function saveAndClose(){
    props.newBody(content, props.type);
    closeMailModal();  
}

return (
    <div key={props.id} className="root-container" onClick={() => isShowMailPopup()}>
      <div className="box-container1" onClick={e => {e.stopPropagation();}}>
      <JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			// onChange={newContent => {}}
		/>
            <div>
                <img  src={backButton} alt="back" className="backImage" onClick={() => isShowMailPopup()}/>
                {!props.readOnly && !props.showSend && <SaveIcon fontSize="large" onClick={() => saveAndClose()}/> }
                {!props.readOnly && props.showSend && <SendIcon fontSize="large" onClick={() => saveAndClose()}/> }
            </div>
        </div>
       </div>
    )
  }