import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import {createTheme, CssBaseline, ThemeProvider,} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// @mui/icons-material

// core components
import Footer from "./Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import GlobalHeader from "./GlobalHeader.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import HousesSection from "./houses/HouseOverView.js";
import House from "./houses/House.js";
import Overview from "./checkout/Overview.js";
import MyBookings from "./booking/MyBookings.tsx";
import MyHouses from "./houses/MyHouses.js";
import {  blue, red  } from "@mui/material/colors";
import MLoginRegister from "./login/MLoginRegister.jsx";

const theme = createTheme({
  palette: {
    primary: red,
    secondary: blue,
  }
});
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
    <div>
      <Parallax filter image={require("assets/img/bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}></h2>
              <h5>
                Reserveer uw vakantie in een van de geweldige huizen op deze site.<br/>
                Bekijk de beschikbaarheid van uw favoriet en reserveer.
              </h5>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
         <BrowserRouter>
         <GlobalHeader />
          <Routes>
            <Route exact path="/" element={<HousesSection />} />
            <Route path='/house/:id' element={<House data = {"hallo"} />} />
            <Route path='/overview'element={<Overview />}/> 
            <Route path="/myBookings" element={<MyBookings />}/>
            <Route path="/myHouses" element={<MyHouses />}/>
            <Route path="/login" element={<MLoginRegister />}/>
          </Routes>
        </BrowserRouter>
        </div>
      </div>
      <Footer />
    </div>
    </ThemeProvider>
  );
}
