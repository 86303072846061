import React, { useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import { setActive } from "../../../services/HouseService.js";
import DateRangePicker from "../../booking/DateRange.js";
import { setUnavailable, removeUnavailable } from "../../../services/BookingService.js";
import { formatDate } from "../../../utils/DateFunctions.js";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DeleteForever } from '@mui/icons-material';
import moment from 'moment/dist/moment.js';
import TabPanel from "../../shared/TabPanel.tsx";

const useStyles = makeStyles(styles);

function OwnUse(props){
    let cookies = props.cookies;
    const token = cookies.get('token');
    const classes = useStyles();
    const house = props.house;
    const [range, setRange] = useState({});
    const [description, setDescription] = useState('');
    const [previous, setPrevious] = useState([]);
    const [counter, setCounter] = useState(0);
    
  function handleCallback (childData) {
      setRange(childData)
  }

  function handleCurrent(childData){
    console.log(childData);
    setPrevious(childData);
  }

  function save(){
    const unavailable = {houseId: house.id,
                         description: description,
                         fromDate: formatDate(range[0].startDate, 2),
                         toDate: formatDate(range[0].endDate, 2)};
    setUnavailable(unavailable,  token).then(res => {
    if (res) {
        console.log("Gelukt");
        setPrevious(previous => ([...previous, res]));
        setCounter(counter + 1);
    } else {
        console.log("Mislukt");
    }
    })
  }

  function onDescriptionChange(e){
    setDescription(e.target.value);
  }

  function deleteUnavailable(id){
    removeUnavailable(id, house.id, token).then(res => {
      if (res) {
          console.log("Gelukt");
          // let ind = previous.findIndex(obj => { return obj.id === id; })
          // previous.splice(ind,1);
          // setPrevious(previous.filter((item) => item.id !== id));
          setCounter(counter + 1);
      } else {
          console.log("Mislukt");
      }
    })
  }

    return (
        <TabPanel value={props.value} index={props.index}>
            <div className={classes.tile}>
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} md={12}> 
                  <Typography className={classes.subTitle}>Blokkeer data voor eigen gebruik etc.</Typography>
                        <DateRangePicker houseId={house.id} parentCallback = {handleCallback} unavailables={handleCurrent} counter={counter}/>
                    <div>
                      <input
                          type="text"
                          name="description"
                          className={classes.descriptionInput}
                          placeholder="Optionele Omschrijving"
                          maxLength={200}
                          onChange={onDescriptionChange
                          .bind(this)}/>
                          <Button
                              type="button"
                              variant="contained"
                              color="error"
                              className="check-btn"
                              onClick={save}
                              >Opslaan
                          </Button>  
                      </div>
                </GridItem>
                
              </GridContainer>
            </div>
            <div className={classes.tile}>
              <GridItem xs={12} sm={12} md={12}> 
                  <Typography className={classes.subTitle}>Bestaande perioden eigen gebruik</Typography>
                    <TableContainer component={Paper} className={classes.gridHeight}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead >
                        <TableRow className={classes.tableRow}>
                          <TableCell>Van</TableCell>
                          <TableCell>Tot</TableCell>
                          <TableCell>Omschrijving</TableCell>
                          <TableCell/>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previous.sort((a, b) => {return moment(a.fromDate).diff(b.fromDate);}).map((prev, idx) => (
                          <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className={classes.tableRow}
                          >
                            <TableCell component="th" scope="row">
                            {moment(prev.fromDate).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell>{moment(prev.toDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>{prev.description}</TableCell>
                            <TableCell className={classes.tableCell}><Button variant="contained" onClick={() => deleteUnavailable(prev.id)}><DeleteForever/></Button></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              </GridItem>
            </div>
        </TabPanel>
    )
}

export default withCookies(OwnUse);