import properties from "properties";

function getRandom(){
  var a = Math.floor((Math.random() * 999999999999) + 999);
  a = String(a);
  a = a.substring(0, 13);
  return parseInt(a);
}

function getApiKey(){
    const date = new Date();
    date.setHours(0,0,0,0);
    // console.log(date);
    // console.log(Math.floor(date.getTime()/1000).toString() );
    // console.log(date.getFullYear()+''+("0" + (date.getMonth()+1)).slice(-2)+''+("0" + date.getDate()).slice(-2));
    const key = (parseInt(properties.apiKey) - Math.floor(date.getTime()/1000)) + ''+date.getFullYear()+''+("0" + (date.getMonth()+1)).slice(-2)+''+("0" + date.getDate()).slice(-2);
    // console.log(key);
    return key;
}

async function getWithKey(url, token){

  const key = await getApiKey();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Key", key );
  if (token)  headers.append("Authorization", token);
  return fetch(url
    , {headers}
        )
                .then(res => res.json())
                .then(json => {
                    //console.log(json)
                    return json;
                })
}

async function getResponseWithKey(url, token){

  const key = await getApiKey();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Key", key );
  if (token)  headers.append("Authorization", token);
  return fetch(url
    , {headers}
        )
}

async function postWithKey(url, body, token){
  const key = await getApiKey();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Key", key );
  if (token)  headers.append("Authorization", token);
  return fetch(url , { method: 'POST', 
    body: body,
    headers  }      
        )
                    .then(res => res.json())
                    .then(json => {
                        //console.log(json)
                        return  json;
                    })
}

async function postResponseWithKey(url, body, token){
  const key = await getApiKey();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Key", key );
  if (token)  headers.append("Authorization", token);
  return fetch(url , { method: 'POST', 
    body: body,
    headers }      
        )                  
}

export {getWithKey, getResponseWithKey, postWithKey, postResponseWithKey}