import React, { Component, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { withCookies, Cookies } from "react-cookie";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import { bookingsForHouse } from "../../services/BookingService.js";
import  now from 'moment';
import * as moment from 'moment';
import {fetchAllHouses, fetchImageUrl} from "../../services/HouseService";
import BookingAccordion from "../shared/BookingAccordion.tsx";
import TabPanel from "../shared/TabPanel.tsx";

const useStyles = makeStyles(styles);

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


function HouseBookings (props) {
  let cookies = props.cookies;
  const user = cookies.get('user');
  const token = cookies.get('token');
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const {data: houseBookings, error: bookingsError, isLoading: bookingsIsLoading } = useQuery(['bookingsForHouse', token], () => bookingsForHouse(props.house.id, token));
  const {data: houses, error: houseError, isLoading: houseIsLoading } = useQuery('houses', fetchAllHouses) 
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [bookingsPast, setBookingsPast]= useState([]);
  const [bookingsPresent, setBookingsPresent]= useState([]);
  const [bookingsFuture, setBookingsFuture]= useState([]);

  //return true, only when all queries are done
  const isLoading = houseIsLoading || bookingsIsLoading;
  //return true, only when all queries run successfully
  const error = houseError || bookingsError;

  useEffect(() => {
    if(houseBookings != undefined && houseBookings.length > 0){
        setBookingsPast(houseBookings.filter(b => moment(b.toDate).isBefore( now())));
        setBookingsPresent(houseBookings.filter(b => moment(b.fromDate).isSameOrBefore(now().add(1,'days')) && moment(b.toDate).isSameOrAfter(now().startOf('day'))));
        setBookingsFuture(houseBookings.filter(b => moment(b.fromDate).isAfter(now())));
    }
    // let counter = 0;
    // if (bookings.bookingsPast > 0) counter ++;
    // if (bookings.bookingsPresent> 0) counter ++;
    // if (bookings.bookingsFuture > 0) counter ++;
    // setValue(counter >= 2 ? 1 : 0);

}, [houseBookings]);

if(isLoading){
    return <div>Loading bookings...</div>
}
if(error){
    return <div>No data found!</div>
}

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // let bookingsPresent = (bookings && bookings != 'undefined' && getNoBookings(bookings) > 0) ;

  function getLabel(str, no){
    return str + " ("+ no + ")";
  }

 function getIndex(id){
  if (id === 0 && bookingsPast.length > 0) return 0;
  if (id === 1 && bookingsPresent.length > 0) {
    if (bookingsPast.length > 0) return 1;
    return 0;
  }
  if (id === 2 && bookingsFuture.length > 0) {
    if (bookingsPast.length > 0 && bookingsPresent.length > 0) return 2;
    if (bookingsPast.length > 0 || bookingsPresent.length > 0) return 1;
    return 0;
  }
 }

  return (
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {bookingsPast.length > 0 && <Tab label={getLabel("Verleden", bookingsPast.length )}{...a11yProps(getIndex(0))} />}
            {bookingsPresent.length > 0 && <Tab label={getLabel("Heden", bookingsPresent.length )} {...a11yProps(getIndex(1))} />}
            {bookingsFuture.length > 0 && <Tab label={getLabel("Toekomst", bookingsFuture.length )} {...a11yProps(getIndex(2))} />}
          </Tabs>
        </Box>
        <BookingAccordion bookings={bookingsPast} houses={houses} value={value} index={getIndex(0)} owner={true}/>
        <BookingAccordion bookings={bookingsPresent} houses={houses} value={value} index={getIndex(1)} owner={true}/>
        <BookingAccordion bookings={bookingsFuture} houses={houses} value={value} index={getIndex(2)} owner={true}/>
      </Box>
        )
 }

export default withCookies(HouseBookings);