import React, { useState } from "react";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import { withCookies } from "react-cookie";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import BorderedGridItem from "../../../components/Grid/BorderedGridItem";
import { getCustomisableMails } from "services/CommunicationService.js";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useQuery} from 'react-query';
import MailPopup from "views/shared/MailPopup.js";
import Button from '@mui/material/Button';
import { setCustomMailType } from "services/CommunicationService.js";
import { getReplacements } from "services/CommunicationService.js";

const useStyles = makeStyles(styles);


function MailTypes(props){
    const classes = useStyles();
    let cookies = props.cookies;
    const token = cookies.get('token');
    const houseId = props.houseId;
    const {data: mailTypes, error: mailTypesError, isLoading: mailTypesLoading } = useQuery(['mailTypes', houseId, token], () =>  getCustomisableMails(token, houseId));
    const {data: repls, error: replsError, isLoading: replsLoading } = useQuery(['replacements'], () =>  getReplacements(token));
    const [showMailModal, setShowMailModal] = useState(0);

    if(mailTypesLoading || replsLoading){
        return <div>Loading for houseId {houseId}...</div>
    }
    if(mailTypesError || replsError){
        return <div>No data found!</div>
    }

    function isShowMailModal(param){  
        console.log(mailTypes);
      setShowMailModal(param); 
    }

    function saveBody(newBody, type){
      console.log(newBody);
      console.log(type);
      const mail = {id: 0
        ,type: type
        ,body: newBody
        };

        setCustomMailType(token, houseId, mail).then(res => {
            console.log(res);
        });
    }
    return (
        <div className={classes.labelAbove}>
            <div>
            <Typography className={classes.subTitle}>Pas hier per type mail uw mailtekst aan:</Typography>
                <GridContainer spacing={2} >
                    <GridItem xs={6} sm={6} md={6} className={classes.accordion0}>
                {mailTypes.length >0  && mailTypes.map((m, idx) => (
                <GridItem className={classes.col1} key={idx}> 

                     <Button onClick={() => setShowMailModal(m.id)} variant="contained" color="success" className={classes.buttonMailType}>{m.type} </Button>
                     {showMailModal === m.id && <MailPopup id={m.id} body={m.body} onMailPopupClose={isShowMailModal} newBody={saveBody} type={m.type}/>}
                 </GridItem>
            ))}
            </GridItem>
                <BorderedGridItem xs={5} sm={5} md={5} className={classes.accordion0}>
                    <div className={classes.text}>
                    U kunt de teksten van een aantal niet automatische mails hiernaast naar believen aanpassen.<br/>
                    Testen en versturen kan bij een boeking, op de 'RESERVERINGEN' tab.<br/>
                    De mogelijke placeholders op dit moment zijn:<br/>
                    </div>
                    <div className={classes.col2}>
                        {repls.slice(0, 9).map(r => (
                        <div>{r} <br/></div>
                    ))}</div>
                    <div className={classes.col2}>
                    {repls.slice(9).map(r => (
                        <div>{r} <br/></div>
                    ))}</div>
                    <div className={classes.text}>
                    Placeholders zijn te gebruiken door deze te omringen met {'{'} en {'}'}, bijv. <code>{`{firstName}`}</code>
                    </div>
                </BorderedGridItem>
            </GridContainer>
            </div>
        </div>
    )
}

export default withCookies(MailTypes);