import properties from "properties";
import {getResponseWithKey, getWithKey, postResponseWithKey, postWithKey} from "./ApiKey";

const USER_API = 'users/';
const USERBYID = 'getUser';

function getUserById(id, token){
    return getWithKey(properties.apiUrl + USER_API + USERBYID + '/' + id, token)
}

export {getUserById}