/*eslint-disable*/
import React, { useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Favorite from "@mui/icons-material/Favorite";
import SimpleModal from "utils/SimpleModal";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const [showModal, setShowModal] = useState(false);

  const about = "<div>Op Holiday-home.online kunt u vakantiehuizen huren en verhuren"+
                "<p>Als eigenaar van een vakantiehuis kunt u binnenkort uw huis hier aanbieden.</p>"+
                "<p>U kunt makkelijk uw huis blokkeren voor eigen gebruik of vanwege verhuur via andere kanalen."+
                "Ook kunt u eenvoudig kortingscodes aanmaken.</p>"+
                "<p>Afhandeling en betaling moet u zelf uitvoeren. Wel kunt u eenvoudig een aantal mail templates gebruiken "+
                "en zullen een aantal taken geautomatiseerd kunnen worden<p></div>";

  function onModalClose(){
    setShowModal(false);

 }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
                <div onClick={() => setShowModal(!showModal)}>Over deze site</div>
                {showModal && <SimpleModal title={"Over holiday-home.online"} description={about} onModalClose={onModalClose} noButton={true}/>}
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} Holiday-home.online
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
