import React, { useState, useEffect, useRef } from "react";
import { useQuery } from 'react-query';
import { withCookies } from "react-cookie";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import { setActive } from "../../../services/HouseService.js";
import TabPanel from "../../shared//TabPanel.tsx";
import HouseBookings from "../../booking/HouseBookings.tsx";

const useStyles = makeStyles(styles);

function Bookings(props){
    const classes = useStyles();
    const house = props.house;

    return (
        <TabPanel value={props.value} index={props.index}>
          {/* <div className={classes.tile}> */}
            {/* <GridContainer spacing={2} > */}
              <HouseBookings house={house}/>
            {/* </GridContainer> */}
          {/* </div> */}
        </TabPanel>
    )
}

export default withCookies(Bookings);