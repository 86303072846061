import properties from "properties";
import {getResponseWithKey, getWithKey, postResponseWithKey, postWithKey} from "./ApiKey";

const AUTH_API = 'auth/';
const LOGIN = 'authenticate';
const REGISTER = 'register';
const FORGOT = 'forgotPassword';
const CHANGE = 'changePassword';

function handleRegisterResponse(response){
    let json = {};
    console.log(response);
    if (response.ok){
        json = response.json();
    }
    return json;
}

// function handleLoginResponse(json){
//     console.log('login');
//     if (json.id && json.token) {json.loggedIn = true;}
//     return json;
// }

function handleLoginResponse(response){
    let json = {};
    console.log(response);
    if (response.ok){
        json = response.json();
        // if (json.id !== null && json.token !== null) {
        //     console.log('hi');
        //     json.loggedIn = true;
        //     json['whatever'] = 'hi';
        // }
    }
    return json;
}

// function  login(email, password){
//     return fetch(properties.apiUrl + AUTH_API + LOGIN + '?email=' + email + '&password=' + password)
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return handleLoginResponse(json);
//                     })
                    
//     }

// function  login(email, password){
//     return fetch(properties.apiUrl + AUTH_API + LOGIN + '?email=' + email + '&password=' + password)
//                     .then(res => handleLoginResponse(res))
                    
//     }

function  login(email, password){
    return getResponseWithKey(properties.apiUrl + AUTH_API + LOGIN + '?email=' + email + '&password=' + password)
                    .then(res => handleLoginResponse(res))
                    
    }

// function  register(user){
//     return fetch(properties.apiUrl + AUTH_API + REGISTER, { method: 'POST', 
//                                         body: JSON.stringify(user), 
//                                         headers:{ 'Content-Type': 'application/json' }})
//                     .then(res => handleRegisterResponse(res)
//                     )
                    
//     }

function  register(user){
    return postResponseWithKey(properties.apiUrl + AUTH_API + REGISTER, JSON.stringify(user))
                    .then(res => handleRegisterResponse(res)
                    )
                    
    }

// function forgotPassword(email, password, random){
//     return fetch(properties.apiUrl + AUTH_API + FORGOT + "?email=" + email + "&password=" + password + "&random=" + random)
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function forgotPassword(email, password, random){
    return getWithKey(properties.apiUrl + AUTH_API + FORGOT + "?email=" + email + "&password=" + password + "&random=" + random)
}

// function changePassword(password, token){
//     return postWithKey(properties.apiUrl + AUTH_API + CHANGE , { method: 'POST', 
//     body: JSON.stringify(password),
//      headers: {
//         'Authorization': token,
//         'Content-Type': 'application/json'}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function changePassword(password, token){
    return postWithKey(properties.apiUrl + AUTH_API + CHANGE , JSON.stringify(password))
}

export {login, register, forgotPassword, changePassword}