import React, { Component, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import { withCookies, Cookies } from "react-cookie";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import General from "./parts/General.tsx";
import OwnUse from "./parts/OwnUse.tsx";
import Discounts from "./parts/Discounts.tsx";
import Bookings from "./parts/Bookings.tsx";

const useStyles = makeStyles(styles);


  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function MyHouse(props){
    let cookies = props.cookies;
    const house = props.house;
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  

  return (
        <div>
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            
             <Tab label={"Algemeen"}{...a11yProps(0)} />
             <Tab label={"Eigen Gebruik"}{...a11yProps(1)} />
             <Tab label={"Korting"}{...a11yProps(2)} />
             <Tab label={"Reserveringen"}{...a11yProps(3)} />

          </Tabs>
        </Box>
        <General house={house} value={value} index={0}/>
        <OwnUse house={house} value={value} index={1}/>
        <Discounts house={house} value={value} index={2}/>
        <Bookings house={house} value={value} index={3}/>
        </Box>
      </div>
        )
 }

export default withCookies(MyHouse);