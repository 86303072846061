import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import GridContainer from "components/Grid/GridContainer.js";
import CompactGridContainer from "components/Grid/CompactGridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useQuery } from 'react-query';
import styles from "assets/jss/material-kit-react/views/landingPageSections/houseStyle.js";
import { withCookies } from "react-cookie";
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fetchHousesOwned, fetchImageUrl } from "services/HouseService";
import classNames from "classnames";
import MyHouse from "./MyHouse.tsx";

const useStyles = makeStyles(styles);

const MyHouses = props =>  {
    let cookies = props.cookies;
    const token = cookies.get('token');
    const {data: houses, error: houseError, isLoading: houseIsLoading } = useQuery(['housesOwned', token], () => fetchHousesOwned(token));
    const [house, setHouse] = useState(0);
    const classes = useStyles();

    if(houseIsLoading){
        return <div>Loading houses...</div>
    }
    if(houseError){
        return <div>No data found!</div>
    }

    return (
        <div><h1 onClick={() => setHouse(0)} className={classes.title} >Mijn huizen</h1>
            <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            >
            {houses && house === 0 ? houses.map(houz => (
            <div style={{marginBottom: '5%'}} key={houz.id} onClick={() => setHouse(houz.id)} className={classes.house}>
                <Card sx={{ width: 300 }} raised={true}>
                    <CardMedia
                        sx={{ height: 240 }}
                        image={fetchImageUrl(houz.id, houz.houseImages[0].path)} 
                        title="first pic"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        {houz.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {houz.houseAddress.street} {houz.houseAddress.number}  {houz.houseAddress.city} 
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            )) : ''}
            {house !== 0 ? 
            <div>
                <MyHouse house={houses.filter(houz => houz.id === house)[0]}/>
            </div>
            : ''}
            </Grid>
        </div>
    )
 }

//  const MyHouses = withCookies(MyHousesOverview);
export default withCookies(MyHouses);