/*eslint-disable*/
import React, { useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @mui/material components
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import { Apps, AccountCircle, DehazeRounded } from "@mui/icons-material";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(styles);

export default function LeftLinks(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies(['user','token','bookings','houses']);

function getNoBookings(bookings){
  const noOfBookings = bookings.bookingsPast + bookings.bookingsPresent + bookings.bookingsFuture;
  return noOfBookings;
}

function housesPresent(){
  return cookies.houses && cookies.houses !== 'undefined' && cookies.houses > 0;
}

function bookingsPresent(){
  return cookies.bookings && cookies.bookings !== 'undefined' && getNoBookings(cookies.bookings) > 0;
}

function getLinkText(){
  let linkText = "Mijn ";
  linkText += bookingsPresent() ? "reserveringen" : "";
  linkText += housesPresent() ? bookingsPresent() ? " & huizen" : "huizen" : "";
  return linkText;
}

return (
    <List className={classes.list}>
      {(bookingsPresent() || housesPresent()) && <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={getLinkText()}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={DehazeRounded}
          dropdownList={[
            (cookies.houses && cookies.houses !== 'undefined' && cookies.houses > 0) && 
            <Link to={"myHouses"}
              className={classes.dropdownLink}
            >
              Mijn huizen <span>({cookies.houses})</span>
            </Link>,
            (cookies.bookings && cookies.bookings !== 'undefined' && getNoBookings(cookies.bookings) > 0) && 
            <Link to={"myBookings"}
            className={classes.dropdownLink}
          >
            Mijn reserveringen <span>({getNoBookings(cookies.bookings)})</span>
          </Link>
          ]}
        />
      </ListItem>}
    </List>
  );
}
