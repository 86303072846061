import properties from "properties";
import {getResponseWithKey, getWithKey, postResponseWithKey, postWithKey} from "./ApiKey";

const BOOKINGS_API = 'bookings/';
const UNAVAILABLE = 'getUnavailables/';
const BOOKINGPRICE = 'getBookingPrices/';
const HOUSEADDITIONAL = 'getAdditionalCosts/';
const BOOK = 'registerBooking/'; 
const DISCOUNT = 'checkDiscount';
const DISCOUNTS = 'getDiscountsForHouse';
const DISCOUNT2 = 'saveDiscount';
const USER = "getBookingsByUser";
const HOUSE = "getBookingsByHouse/";
const ETA = "setEta";
const UNAVAILABLE2 = "setUnavailables";
const UNAVAILABLE3 = "removeUnavailable";
const STATUS = "setBookingStatus";

// function  fetchUnavailableDates(houseId){
//     return fetch(properties.apiUrl + BOOKINGS_API + UNAVAILABLE + houseId)
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
                    
//     }

function  fetchUnavailableDates(houseId){
    return getWithKey(properties.apiUrl + BOOKINGS_API + UNAVAILABLE + houseId)
}

// function  fetchBookingPrices(houseId){
//     return fetch(properties.apiUrl + BOOKINGS_API + BOOKINGPRICE + houseId)
//                     .then(res => res.json())
//                     .then(json => {
//                         return json;
//                     })
                    
//     }

function  fetchBookingPrices(houseId){
    return getWithKey(properties.apiUrl + BOOKINGS_API + BOOKINGPRICE + houseId)
}


// function  fetchAdditionalCosts(houseId, token){

//     return fetch(properties.apiUrl + BOOKINGS_API + HOUSEADDITIONAL + houseId
//         , {headers: {
//             'Authorization': token}}
//         )
//                     .then(res => {
//                         if (res.status === 401) return {unauthorized: true}
//                         if (res.status === 200) return res.json()
//                     })
                                        
//     }

function  fetchAdditionalCosts(houseId, token){
    return getResponseWithKey(properties.apiUrl + BOOKINGS_API + HOUSEADDITIONAL + houseId, token)
                    .then(res => {
                                if (res.status === 401) return {unauthorized: true}
                                if (res.status === 200) return res.json()
                            })
}


// function  book(booking, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + BOOK, { method: 'POST', 
//                                         body: JSON.stringify(booking), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res => {return res} 
//                     )
                    
//     }

function  book(booking, token){
    return postResponseWithKey(properties.apiUrl + BOOKINGS_API + BOOK, JSON.stringify(booking), token).then(res => {return res} )
}

// function  checkDiscountCode(discountCode, houseId, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + DISCOUNT + '?discountCode=' + discountCode + '&houseId=' + houseId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
                    
//     }

    
function  checkDiscountCode(discountCode, houseId, token){
    return getWithKey(properties.apiUrl + BOOKINGS_API + DISCOUNT + '?discountCode=' + discountCode + '&houseId=' + houseId, token)                    
    }

// function  fetchDiscountCodes(houseId, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + DISCOUNTS + '?houseId=' + houseId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
                    
//     }

function  fetchDiscountCodes(houseId, token){
    return getWithKey(properties.apiUrl + BOOKINGS_API + DISCOUNTS + '?houseId=' + houseId, token)
}

// function  saveDiscount(discount, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + DISCOUNT2, { method: 'POST', 
//                                         body: JSON.stringify(discount), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res=>
//                         {return  res;}
                    
//                     )
                    
//     }

function  saveDiscount(discount, token){
    return postResponseWithKey(properties.apiUrl + BOOKINGS_API + DISCOUNT2, JSON.stringify(discount), token).then(res=>
                                {return  res;})
}

// function bookingsForUser(token){
//     return fetch(properties.apiUrl + BOOKINGS_API + USER
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function bookingsForUser(token){
    return getWithKey(properties.apiUrl + BOOKINGS_API + USER, token)
}

// function bookingsForHouse(houseId, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + HOUSE + "?houseId=" + houseId
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function bookingsForHouse(houseId, token){
    return getWithKey(properties.apiUrl + BOOKINGS_API + HOUSE + "?houseId=" + houseId, token)
}

// function setEtaForBooking(bookingId, eta, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + ETA  + '?bookingId=' + bookingId + '&eta=' + eta
//         , {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function setEtaForBooking(bookingId, eta, token){
    return getWithKey(properties.apiUrl + BOOKINGS_API + ETA  + '?bookingId=' + bookingId + '&eta=' + eta, token)
}

// function  setUnavailable(unavailable, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + UNAVAILABLE2, { method: 'POST', 
//                                         body: JSON.stringify(unavailable), 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                                         .then(res => res.json())
//                                         .then(json => {
//                                             //console.log(json)
//                                             return  json;
//                                         })
                    
//     }

function  setUnavailable(unavailable, token){
    return postResponseWithKey(properties.apiUrl + BOOKINGS_API + UNAVAILABLE2,  JSON.stringify(unavailable), token)
}

// function  removeUnavailable(id, houseId, token){
//     return fetch(properties.apiUrl + BOOKINGS_API + UNAVAILABLE3 + '?houseId=' + houseId + '&unavId=' + id, { method: 'GET', 
//                                         headers:{ 'Content-Type': 'application/json',  'Authorization': token }})
//                     .then(res => {return res} 
//                     )
                    
//     }

function  removeUnavailable(id, houseId, token){
    return getResponseWithKey(properties.apiUrl + BOOKINGS_API + UNAVAILABLE3 + '?houseId=' + houseId + '&unavId=' + id, token)
            .then(res => {return res} )
}


export {fetchUnavailableDates, fetchBookingPrices, fetchAdditionalCosts, book, checkDiscountCode, bookingsForUser, setEtaForBooking, setUnavailable, removeUnavailable, fetchDiscountCodes, saveDiscount, bookingsForHouse}