import React, { useState } from "react";
import {fetchImageUrl} from "../../services/HouseService";
import * as moment from 'moment';
import  now from 'moment';
import { useQuery } from 'react-query';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import BorderedGridItem from "../../components/Grid/BorderedGridItem";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import { getStatus } from "../booking/BookingFunctions";
import { getHtml } from "../../utils/StringFunctions";
import CurrencyFormat from 'react-currency-format';
import Chat from "./Chat";
import { isEvenOrOdd } from "../../utils/NumberFunctions";
import Review from "../booking/Review";
import TimePick from "../../views/booking/TimePick";
import Mails from "../booking/Mails"
import TabPanel from "./TabPanel.tsx";
import NotifyButtons from "./NotifyButtons";
import paid50 from '../../assets/img/50.png';
import paid100 from '../../assets/img/100.png';
import cancelled from '../../assets/img/cancel.png';
import confirmed from '../../assets/img/check.png';
import initial from '../../assets/img/star.png';
import { getUserById } from "services/UserService";
import { withCookies, Cookies } from "react-cookie";
import Button from '@mui/material/Button';

const useStyles = makeStyles(styles);

function BookingAccordion (props){
  let cookies = props.cookies;
  const token = cookies.get('token');
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);
  // const {data, error, isError, isLoading } = useQuery('user', getUserById());
  const [user, setUser] = useState({});
  const [showFeat, setShowFeat] = useState(false);

function formatDate (date, type) {
    return type === 1 ? moment(date).format('DD-MM-YYYY') : moment(date).format('YYYY-MM-DD');
  }

  const handleExpand =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getAccordionClass(index) {
    if (isEvenOrOdd(index)) return classes.accordion0;
    return classes.accordion1;
 }

 function getReverseAccordionClass(index) {
  if (isEvenOrOdd(index)) return classes.accordion1;
  return classes.accordion0;
}

 function getTileClass(index){
    if (isEvenOrOdd(index)) return classes.accordion1;
    return classes.accordion0;
 }

 function getIcons(booking){
  return (<>
    {!booking.confirmed && !booking.cancelledByOwner && !booking.cancelledByClient &&
  <img  src={initial} alt="initial" className={classes.icon}/>}
    {booking.confirmed && <img  src={confirmed} alt="confirmed" className={classes.icon}/>}
    {booking.paid && <img  src={paid100} alt="paid" className={classes.icon}/>}
    {booking.paid50 && <img  src={paid50} alt="paid50" className={classes.icon}/>}
    {(booking.cancelledByOwner || booking.cancelledByClient) && <img  src={cancelled} alt="cancelled" className={classes.icon}/>}
  </>)
 }

 function getUser(booking){
    getUserById(booking.userId, token).then(res => {
      if (res) {
         res.userIdBooking = booking.userId;
          setUser(res);
          console.log(user);
      } else {
          console.log("Mislukt");
      }
    });
 }

 function userBlock(booking, idx){
  if (booking.userId === user.userIdBooking) {
  return (
    <BorderedGridItem xs={9} sm={9} md={9} className={getReverseAccordionClass(idx)}>
     {user.nameFirst}  {(user.nameMiddle !== null ? user.nameMiddle : '')}   {user.nameLast }<br/>
     {user.userEmail}<br/>
     {user.userAddress.street} {user.userAddress.number} {user.userAddress.other} <br/>
     {user.userAddress.zip} {user.userAddress.city} {user.userAddress.country}<br/>
     {user.userTelephone}<br/><br/>
     Volwassenen: {booking.adults}<br/>
     {booking.kids > 0 && <div>Kinderen   :  {booking.kids} <br/></div>}
     {booking.pets > 0 && <div>Huisdieren : {booking.pets} <br/></div>}
     {booking.discountId !== null && 'Kortingscode ' + booking.discountId + ' gebruikt'}
    </BorderedGridItem>
  )
    } else {
      return '';
    }
 }

    return (
        <TabPanel value={props.value} index={props.index}>
        {props.bookings.map((book, idx) => (
            props.houses.filter(hz => hz.id === book.houseId).map(houz => (
              <Accordion key={idx} expanded={expanded === 'panel' + idx} onChange={handleExpand('panel' + idx) } className={getAccordionClass(idx)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography component="span">{formatDate(book.fromDate, 1)} - {formatDate(book.toDate, 1)}  {houz.name} in {houz.houseAddress.city}, {houz.houseAddress.country} </Typography>
                {getIcons(book)}
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="span">
                <GridContainer spacing={2}>
                  <GridItem xs={3} sm={3} md={3}>
                    <img  src={fetchImageUrl(houz.id, houz.houseImages[0].path)} alt="First Pic" className={classes.image}/>
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9}>
                    <GridContainer spacing={2}>
                      <GridItem xs={9} sm={9} md={9}>
                        Totaal prijs: <CurrencyFormat value={book.totalPrice} displayType={'text'} thousandSeparator={ '.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€'}/>
                      </GridItem>
                      {props.owner && 
                      <GridContainer spacing={2}>
                      <GridItem xs={9} sm={9} md={9}>
                        <Button onClick={() => getUser(book)} variant={"outlined"}> Gegevens huurder</Button>
                      </GridItem>
                      {userBlock(book, idx)}
                      </GridContainer>
                      }
                      <GridItem xs={9} sm={9} md={9}>
                        Status: {getStatus(book)}
                        Notificatie: {<NotifyButtons booking={book} owner={props.owner} houseId={houz.id}/>}
                      </GridItem>
                      <GridItem xs={9} sm={9} md={9}>
                        Adres: {houz.houseAddress.city}
                      </GridItem>
                      <Button onClick={() => setShowFeat(!showFeat)} variant={"outlined"}> Huis eigenschappen</Button>
                      {showFeat && houz.features.sort((a,b) =>  a.seqNo-b.seqNo ).map((filteredFeat, idx2) => (
                            <GridItem xs={12} sm={12} md={12} key={idx2}>
                                <li className={classes.featureTitle}>{filteredFeat.feature}</li>
                                <div dangerouslySetInnerHTML = {getHtml(filteredFeat.value)}></div>
                            </GridItem>
                        ))}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer spacing={2}>
                  <BorderedGridItem xs={6} sm={6} md={6} className={getTileClass(idx)}>
                    <Chat booking={book}/>
                  </BorderedGridItem>
                  <BorderedGridItem xs={5} sm={5} md={5} className={getTileClass(idx)}>
                  {moment(book.toDate).isBefore(now()) && <Review bookingId={book.id} house={houz} />}
                  {moment(book.fromDate).isAfter(now()) && !props.owner && <TimePick bookingId={book.id}/>}
                  
                  </BorderedGridItem>
                </GridContainer>
                <GridContainer spacing={2}>
                  <BorderedGridItem xs={12} sm={12} md={12} className={getTileClass(idx)}>
                  {props.owner && <Mails bookingId={book.id} houseId={houz.id}/>}
                  </BorderedGridItem>
                  </GridContainer>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
          ))}
    </TabPanel>
    )
}

export default withCookies(BookingAccordion);