import React, { useState }from "react";
import FadeTransition from "./FadeTransition";
import "./loginStyles.scss";
import backButton from '../../assets/img/back.png';
import { setRandom } from "services/CommunicationService";
import { forgotPassword, changePassword } from "services/AuthService";
import { useCookies } from 'react-cookie';
import {isMobile} from 'react-device-detect';
import is from "date-fns/esm/locale/is/index.js";

export default function ChangePassword(props) {

  const [cookies, setCookie] = useCookies(['user','token']);
  const [showChangePassword, setShowChangePassword] = useState(true);
  const [password, setPassword] = useState({password: '', passwordVerify: ''});
  const [email, setEmail] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [error, setError] = useState('');


    function isShowChangePassword(){  
        closeCPModal();  
        // setShowChangePassword(true);
    } 

    function closeCPModal() {
        props.onChangePasswordClose(false);
    };

    function validateForm() {
      const valid = (props.loggedIn ? props.loggedIn : email.length > 0 && verifyCode.length > 0) && password.password.length > 0 && password.passwordVerify.length > 0 && password.password === password.passwordVerify;
      return valid;
    }

    function validateEmail() {
      const valid = (props.loggedIn ? props.loggedIn : email.length > 0) && email.indexOf("@") > -1;
      console.log(valid);
      return valid;
    }

    function onPasswordChange(e) {
      const pass = {password: e.target.value};
      setPassword(password => ({
        ...password,
        ...pass
    }))
    }

    function onPasswordVerify(e) {
      const pass = {passwordVerify: e.target.value};
      setPassword(password => ({
        ...password,
        ...pass
    }))
    }

    function onEmailChange(e) {
      setEmail(e.target.value);
    }

    function onVerifyCodeChange(e) {
      setVerifyCode(e.target.value);
    }

    async function  submitPassword(e) {
      let json = {};
      const token = cookies.token;
      if (props.loggedIn) {
        json = await changePassword(password.password, token);
      } else {
        json = await forgotPassword(email, password.password,verifyCode);
      }
      if (json) isShowChangePassword(false);
      setError("Er ging iets fout, probeer later nog eens")
    }

    function  mailRandom(e) {
      const json = setRandom(email);
    }
  
    return (
        <div className="root-container" onClick={() => isShowChangePassword(false)}>
          <div className="box-container1" onClick={e => {e.stopPropagation();}}>
              <div>
                  <FadeTransition isOpen={showChangePassword} duration={100}>
                    <div className={isMobile ? "box-container1-m" : "box-container1"}>
                    <div className="inner-container">
                      <div className="header">
                        {props.loggedIn ? 'Wachtwoord veranderen' : 'Wachtwoord'}
                      </div>
                      <div className="box">
                      <div className={props.loggedIn ? "hidden" : ""}>
                        <div className="input-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            name="email"
                            className="login-input"
                            placeholder="Email"
                            onChange={onEmailChange
                              .bind(this)}/>
                        </div>
                        <button
                          type="button"
                          className="login-btn"
                          onClick={mailRandom
                          .bind(this)} 
                          disabled={!validateEmail()}
                          >Verifieer Email Adres
                          
                          </button>
                        <div className="input-group">
                          <label htmlFor="verify">Verificatie Code</label>
                          <input
                            type="text"
                            name="verify"
                            className="login-input"
                            placeholder="Verify Code"
                            onChange={onVerifyCodeChange
                              .bind(this)}/>
                        </div>
                      </div>
                        <div className="input-group">
                          <label htmlFor="password">Wachtwoord</label>
                          <input
                            type="password"
                            name="password"
                            className="login-input"
                            placeholder="Password"
                            onChange={onPasswordChange
                              .bind(this)}/>
                        </div>
                        <div className="input-group">
                          <label htmlFor="passwordAgain">Wachtwoord nogmaals</label>
                          <input
                            type="password"
                            name="passwordAgain"
                            className="login-input"
                            placeholder="Password Verify"
                            onChange={onPasswordVerify
                              .bind(this)}/>
                        </div>

                        <button
                          type="button"
                          className="login-btn"
                          onClick={submitPassword
                          .bind(this)} 
                          disabled={!validateForm()} >Verander Wachtwoord
                          </button>
                          <div className="error" >{error}</div>
                      </div>
                    </div>
                    </div>
                  </FadeTransition>
                  <div>
                      {/* <button  onClick={() => this.isShowModal(true)} >
                       Close
                     </button> */}
                     <img  src={backButton} alt="back" className="backImage" onClick={() => isShowChangePassword(false)}/>
                  </div>
              </div>
        </div>
       </div>
    )
  }