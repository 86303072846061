import React from "react";
import Header from "./Header/Header.js";
import HeaderLinks from "./Header/HeaderLinks.js";
import LeftLinks from "./Header/LeftLinks.js";
import {fetchImagePNGUrl} from "services/HouseService";
import {isMobile} from 'react-device-detect';
import { makeStyles } from "@mui/styles";
import styles from "assets/jss/material-kit-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

const GlobalHeader = () => {
    const dashboardRoutes = [];
    const classes = useStyles();
    return(
        <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img  className={isMobile ? classes.mLogo : classes.logo} src={fetchImagePNGUrl('logohh.png')} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        leftLinks={<LeftLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "offwhite"
        }}
        menu={{
          color: "white"
        }}
        menuScroll={{
          color: "black"
        }}
        // {...rest}
      />
    )
}

export default GlobalHeader;