import { title } from "../../../material-kit-react.js";

const bookingsStyle = {
//   section: {
//     padding: "70px 0",
//     textAlign: "center",
//   },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  subTitle: {
    marginBottom: "30px !important",
    marginLeft: "15px !important"
  },
  description: {
    color: "#00796b",
    marginLeft: "2%"
  },
  resTitle: {
    color: "#00796b",
    fontSize: "calc(.5em + 1vw)"
  },
  resText: {
    color: "#00796b"
  },
  image: {
    width: "100%",
    marginLeft: "2%"
  },
  featureTitle: {
    color: "black",
    textTransform: "uppercase",
    listStyleType: "square"
  },
  labelText: {
    verticalAlign: "top",
    display: "block"
  },
  labelTextMargin: {
    verticalAlign: "top",
    display: "block",
    marginBottom: "15px"
  },
  labelAbove: {
    display: "block !important"
  },
  accordion0: {
    backgroundColor: "#e0f2f1 !important",
    marginLeft: "30px !important"
  },
  accordion1: {
    backgroundColor: "#d6dec4 !important",
    marginLeft: "30px !important"
  },
  messageBox: {
    border: "2px solid #dedede",
    backgroundColor: "#b0d35f",
    borderRadius: "5px",
    padding: "10px",
    margin: "10px 0",
    float: "left",
    width: "70%"
  },
  messageBox1: {
    border: "2px solid #dedede",
    backgroundColor: "#b0d35f",
    borderRadius: "5px",
    padding: "10px",
    margin: "10px 0",
    float: "left",
    width: "90%"
  },
  buttonMailType: {
    textTransform: "inherit !important",
    width: "100%"
  },
  col1: {
    marginBottom: "15px !important"
  },
  col2: {
    display: "inline-block",
    position: "relative",
    marginRight: "5%"
    },
  text: {
      backgroundColor: "#d6dec4 !important",
      fontSize: "small",
      marginTop: "15px",
      marginBottom: "15px"
    },
  icon: {
    marginLeft: "1%",
    width: "3%"
  }
};
export default bookingsStyle;