const houseStyle = {
    //   section: {
    //     padding: "70px 0",
    //     textAlign: "center",
    //   },
      title: {
        cursor: "pointer",
        color: "#00796b",
      },
      house: {
        cursor: "pointer",
        marginRight: '5%'
      },
      subTitle: {
        paddingLeft: "10px",
        paddingBottom: "2%"
      },
      container: {
        border: "2px solid #dedede",
        backgroundColor: "#b0d35f",
        borderRadius: "5px",
        padding: "10px",
        margin: "10px 0",
        float: "left",
        color: "#00796b",
      },
      tile: {
        borderRadius: "15px",
        boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        background: "#d6dec4",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginBottom: "2%", 
        width: "100%",
        color: "#00796b",
      },
      descriptionInput: {
        height: "1.8em",
        width: "70%",
        borderRadius: "3px",
        border: "1px solid $blue_theme",
        transition: "border 0.4s ease-out",
        margin: "2%",
        fontFamily: "Oxygen, sans-serif",
        fontSize: "18px",
        color: "#3e3e42",
        background: "rgba(15, 15, 15, 0.01)"
      },
      previous: {
        maxWidth: "100% !important"
      },
      tableRow: {
        height: "10px !important",
      },
      tableCell: {
        padding: "1px !important"
      },
      gridHeight: {
        maxHeight: "350px"
      },
      smallContainer: {
        margin: "0 !important"
      },
      errorText: {
        color: "red"
      },
    }
export default houseStyle;