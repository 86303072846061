import properties from "properties";
import {getWithKey, postWithKey} from "./ApiKey";

const HOUSE_API = 'houses/';
const ALL = 'getAllHouses';
const ONE = 'getHouse/';
const IMAGE = 'getImage/';
const PNG= 'getImagePNG';
const REVIEW = 'addReview';
const USER = "getOwnedHouses";
const ACTIVE="setActive";
const TERMS="getTermsPdf/";

// async function  fetchAllHouses(){
//     const key = await getApiKey();
//     return fetch(properties.apiUrl + HOUSE_API + ALL
//         , {headers: {
//             'X-Key': key}}
//             )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return json;
//                     })
                    
//     }

async function  fetchAllHouses(){
    return getWithKey(properties.apiUrl + HOUSE_API + ALL)
    }

// function  fetchHouse(houseId){
//     console.log(houseId);
//     return fetch(properties.apiUrl + HOUSE_API + ONE + houseId)
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return json;
//                     })
                    
//     }

async function  fetchHouse(houseId){
    return getWithKey(properties.apiUrl + HOUSE_API +  ONE + houseId)
    }

function fetchImageUrl (houseId, fileName) {
    return (properties.apiUrl + HOUSE_API + IMAGE + houseId + '/' + fileName);
    }

function fetchImagePNGUrl (fileName) {
    return (properties.apiUrl + HOUSE_API + PNG + '/' + fileName);
    }

function fetchTermsPdfUrl (houseId) {
    return (properties.apiUrl + HOUSE_API + TERMS + houseId);
    }

// function addReview(review, token){
//     return fetch(properties.apiUrl + HOUSE_API + REVIEW , { method: 'POST', 
//     body: JSON.stringify(review),
//         headers: {
//         'Authorization': token,
//         'Content-Type': 'application/json'}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return  json;
//                     })
    
// }

function addReview(review, token){
    return postWithKey(properties.apiUrl + HOUSE_API + REVIEW, JSON.stringify(review), token)
}

// function  fetchHousesOwned(token){
//     return fetch(properties.apiUrl + HOUSE_API + USER, {headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return json;
//                     })
                    
//     }

function  fetchHousesOwned(token){
    return getWithKey(properties.apiUrl + HOUSE_API + USER, token)
}

// function  setActive(houseId, active, token){
//     return fetch(properties.apiUrl + HOUSE_API + ACTIVE + "?houseId=" + houseId + "&active=" + active ,  { method: 'GET',headers: {
//         'Authorization': token}}
//         )
//                     .then(res => res.json())
//                     .then(json => {
//                         //console.log(json)
//                         return json;
//                     })
                    
//     }

function  setActive(houseId, active, token){
    return getWithKey(properties.apiUrl + HOUSE_API + ACTIVE + "?houseId=" + houseId + "&active=" + active, token)
}


export {fetchAllHouses, fetchImageUrl, fetchImagePNGUrl, fetchTermsPdfUrl, fetchHouse, addReview, fetchHousesOwned, setActive}