import React, { useState } from "react";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/bookingsStyle.js";
import { makeStyles } from "@mui/styles";
import { setEtaForBooking } from "services/BookingService.js";
import { withCookies } from "react-cookie";

const useStyles = makeStyles(styles);


function TimePick(props){
    const classes = useStyles();
    const [value, setValue] = useState('');
    let cookies = props.cookies;
    const token = cookies.get('token');

    function submitEta(eta){
        setValue(eta);
        setEtaForBooking(props.bookingId, eta, token).then(res => {
            if (res.ok) {
                console.log('eta submitted');
            } else {
                console.log('error submitting eta!');
            }
        })
    }

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={classes.messageBox1}>
            <label className={classes.labelTextMargin}>Geef a.u.b. uw verwachte aankomsttijd aan:</label>

            <TimePicker
                label="Geschatte aankomsttijd"
                value={value}
                ampm={false}
                onChange={(newValue) => {
                    submitEta(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </div>
    </LocalizationProvider>
  );
}

export default withCookies(TimePick);
