import React from "react";
import { login } from "services/AuthService";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

class NormalLoginBox extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { email: "",
    password: "",
    error: ""};
  }

  async submitLogin(e) {
    const json = await login(this.state.email, this.state.password);
    const { cookies } = this.props;
    console.log(json);
    if (!json.token ) 
      {this.setState({error: 'Gebruiker niet gevonden of fout wachtwoord!'});
      this.props.cookies.remove('user');
      this.props.cookies.remove('token');
      this.props.cookies.remove('bookings');
      this.props.cookies.remove('houses');
      this.props.cookies.remove('userId');
    }
    else {
      this.setState({error: ''});
      cookies.set('user', json.nameFirst + ' ' + (json.nameMiddle ? json.nameMiddle + ' ' : '') + json.nameLast, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
      cookies.set('token', json.token, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
      cookies.set('bookings', json.bookingsForUser, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
      cookies.set('houses', json.housesOwned, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
      cookies.set('userId', json.id, { path: '/', secure: true, sameSite: 'none', maxAge: 60 * 60 * 5});
      this.props.closeModal({status: 2});}
  }

  onEmailChange(e) {
    this.setState({email: e.target.value});
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  render() {
    return (
      <div className="inner-container">
        <div className="header">
          Inloggen
        </div>
        <div className="box">

          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              className="login-input"
              placeholder="Email"
              onChange={this
                .onEmailChange
                .bind(this)}/>
          </div>

          <div className="input-group">
            <label htmlFor="password">Wachtwoord</label>
            <input
              type="password"
              name="password"
              className="login-input"
              placeholder="Wachtwoord"
              onChange={this
                .onPasswordChange
                .bind(this)}/>
          </div>

          <button
            type="button"
            className="login-btn"
            onClick={this
            .submitLogin
            .bind(this)} 
            disabled={!this.validateForm()} >Inloggen
            </button>
            <div className="error" >{this.state.error}</div>
        </div>
      </div>
    );
  }

  
  }

  const LoginBox = withCookies(NormalLoginBox);
  export { LoginBox };