import { DateRange } from 'react-date-range';
import React, {useEffect} from 'react';
import { addDays,subDays } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import '../../assets/scss/custom/dateRange.scss';
import { fetchUnavailableDates } from "services/BookingService";
import {isMobile} from 'react-device-detect';

export default function DateRangePicker(props)  {

    const houseId = props.houseId;
    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        old: false
      }
    ]);
  const [edates, setEdates]=useState([]); 
  const [unavailables, setUnavailables]=useState([]);
    
    const {dates, error, isError, isLoading } = useQuery('unavailables',  
                                                                      () => fetchUnavailableDates(houseId), 
                                                                      {onSuccess: (data) => 
                                                                        {setEdates(intervalDates(data))}});


    useEffect(() => {
       props.parentCallback(state);
     }, [state]);

     useEffect(() => {
      if (props.unavailables) props.unavailables(unavailables);
     }, [edates]);
     
     useEffect(() => {
      fetchUnavailableDates(houseId).then(res => {
            if (res) {
                setEdates(intervalDates(res));
            } else {
                console.log("Mislukt");
            }
        })
     }, [props.counter])

     if(isLoading){
         return <div>Loading...</div>
     }
     if(isError){
         return <div>Error! {error.message}</div>
     }

     function createDate (dateString){
       if (dateString==undefined) return;
       let splittedDate = dateString.split("-");
       let retdate = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2].substr(0,2));
       return retdate;
     }

     function intervalDates(dateArray){
      let newDateList = [];
      for (var i in dateArray){
        let toDay = createDate(dateArray[i].toDate);
        let fromDay = createDate(dateArray[i].fromDate);
       for (var d = fromDay; d <= toDay; d.setDate(d.getDate() + 1)) {
        newDateList.push(new Date(d));
        }
      }
      setUnavailables(dateArray.filter(date => !date.booking));
      return newDateList;
     }

    return (
      <DateRange
      onChange={item => {console.log('select');console.log(item.selection);item.selection.old = false; setState([item.selection])}}
      moveRangeOnFirstSelection={false}
      months={isMobile ? 1 : 2}
      ranges={state}
      direction="horizontal"
      disabledDates={edates}
      monthWidth={1}
      monthHeight={1}
      />
    )
  }

